<template>
  <div class="container" @contextmenu.prevent>
    <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link to="user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          </div>
    <div class="row">
      <div class="col-12">
        <div class="card" style="min-height: 80vh">
          <div class="card-body">
            <h1 class="card-title" style="margin-top: 20px; margin-bottom: 20px;">
              Prueba de Aptitudes Lenguaje
            </h1>
            <div v-if="currentSection === 0" class="start-button mb-3"><h2>Prueba 1</h2>
              <div v-if="!isMobile()" style="text-align:left; margin-left:30vw; width: 50%">
                <p>1. Tienes 1 minuto y 6 segundos por ejercicio en la prueba 1.</p>
                <p>2. Si un ejercicio te toma mucho tiempo o no sabes resolverlo pasa al siguiente.</p>
                <p>3. Si te sobra tiempo regresa a los ejercicios que saltaste.</p>
              </div>
              <div v-if="isMobile()" style="text-align:left">
                <p>1. Tienes 1 minuto y 6 segundos por ejercicio en la prueba 1.</p>
                <p>2. Si un ejercicio te toma mucho tiempo o no sabes resolverlo pasa al siguiente.</p>
                <p>3. Si te sobra tiempo regresa a los ejercicios que saltaste.</p>
              </div>
              <button class="btn btn-primary" @click="startSection(1)">
                Iniciar
              </button>
            </div>
            <div v-if="currentSection === -1" class="start-button mb-3"><h2>Prueba 2</h2>
              <div v-if="!isMobile()" style="text-align:left; margin-left:30vw; width: 50%">
                <p>1. Tienes 1 minuto y 12 segundos por ejercicio en la prueba 2.</p>
                <p>2. Si un ejercicio te toma mucho tiempo o no sabes resolverlo pasa al siguiente.</p>
                <p>3. Si te sobra tiempo regresa a los ejercicios que saltaste.</p>
              </div>
              <div v-if="isMobile()" style="text-align:left">
                <p>1. Tienes 1 minuto y 12 segundos por ejercicio en la prueba 2.</p>
                <p>2. Si un ejercicio te toma mucho tiempo o no sabes resolverlo pasa al siguiente.</p>
                <p>3. Si te sobra tiempo regresa a los ejercicios que saltaste.</p>
              </div>
              <button class="btn btn-primary" @click="startSection(2)">
              Iniciar
              </button>
          </div>
          <div v-if="currentSection === -2" class="see-results mb-3">
              <button class="btn btn-primary" @click="seeResults">
              Ver Resultados
              </button>
          </div>
          <div v-if="currentSection === 1 || currentSection === 2">
              <div class="timer mb-3" v-if="!isLoading">
                  <div class="time-remaining-box">
                      <span class="time-remaining-value" :class="timerStyle">{{ timeRemaining }}</span>
                  </div>
                  </div>
            <h5 class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">
              Problema {{ currentProblemIndex + 1 }} de {{ totalProblems }}
            </h5>
            <div class="problem-container">
              <img v-show='currentProblem.hasFigure==1' class="problem-image" :src="`https://mathpaas-documents.s3.amazonaws.com/images/${currentProblem.Draw}.png`" alt="Assessment image">
              <div v-if="isLoading" class="loading-animation">
                <div class="spinner-border text-primary" role="status">
                  <span class="visually-hidden">Cargando...</span>
                </div>
              </div>
              <template v-else>
                <div
                :class="!isMobile() ? 'formatted-text-wrapper' : 'formatted-text-wrapper-mobile'">
                <FormattedText :content="currentProblem.Problem"/>
                </div>
                <div
                  v-if="currentProblemIndex < numberProblems1+numberProblems2"
                  class="options"
                  style="margin-top: 30px;"
                >
                  <div
                    class="form-check"
                    v-for="(option, index) in currentProblem.OptionLabels"
                    :key="index"
                    :class="{ 'selected-option': isSelected(index) }"
                  >
                    <input
                      class="form-check-input"
                      type="radio"
                      name="option"
                      :id="'option' + index"
                      :value="index"
                      v-model="selectedOption"
                    />
                    <label
                      style="margin-right: 10px;"
                      class="form-check-label"
                      :for="'option' + index"
                      >
                      <div>
                      <FormattedText :content="option.label + '. ' + option.text"/>
                    </div></label
                    >
                  </div>
                </div>
                <div
                  v-else
                  class="answer-input"
                  style="margin-top: 30px;"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      id="userAnswer"
                      v-model="userAnswer"
                      placeholder="Escribe tu respuesta aquí"
                    />
                  </div>
                </div>
              </template>
              <div class="buttons d-flex flex-column" v-if="!isLoading && !isMobile()">
                  <div class="submit-button-container mb-3">
                      <button
                      class="btn btn-primary equal-width" @click="submitAnswer"
                    >
                    Enviar
                    </button>
                </div>
                <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                    <div class="buttons-left">
                    <button
                        class="btn btn-secondary equal-width"
                        @click="previousProblem"
                    >
                        Anterior
                    </button>
                    </div>
                    <div class="next-section-button">
                    <button
                        class="btn btn-primary"
                        v-show="allProblemsAnswered && currentSection === 1"
                        @click="nextSection"
                    >
                        Ir a Prueba 2
                    </button>
                    <button
                        class="btn btn-primary"
                        v-show="allProblemsAnswered && currentSection === 2"
                        @click="finishTest"
                    >
                        Terminar Examen
                    </button>
                    </div>
                    <div class="buttons-right">
                    <button
                        class="btn btn-secondary equal-width"
                        @click="nextProblem"
                    >
                        Siguiente
                    </button>
                    </div>
                </div>
                </div>
          </div>
          <div class="buttons d-flex flex-column" style="width: 100%;" v-if="!isLoading && isMobile()">
                  <div>
                      <button
                      class="btn btn-primary equal-width" style="width: 60%; height: 40px;" @click="submitAnswer"
                    >
                    Enviar
                    </button>
                </div>
                <div class="d-flex flex-row justify-content-between" style="width: 100%; margin-top: 20px;">
                    <button
                        class="btn btn-secondary equal-width" style="width: 100%; height: 40px;"
                        @click="previousProblem"
                    >
                        Anterior
                    </button>
                    <button
                        class="btn btn-secondary equal-width" style="width: 100%; height: 40px;"
                        @click="nextProblem"
                    >
                        Siguiente
                    </button>
                </div>
                <div class="next-section-button" style="margin-top: 20px;">
                    <button
                        class="btn btn-primary" style="width: 60%; height: 40px;"
                        v-show="allProblemsAnswered && currentSection === 1"
                        @click="nextSection"
                    >
                        Ir a Prueba 2
                    </button>
                    <button
                        class="btn btn-primary"
                        v-show="allProblemsAnswered && currentSection === 2"
                        @click="finishTest"
                    >
                        Terminar Examen
                    </button>
                    </div>
                </div>
          <div class="thumbnails-container" v-if="currentProblem && !isLoading && !isMobile()">
            <div class="row" style="width: 60%;">
                <div class="col-12 d-flex justify-content-center">
                <button
                    v-for="(problem, index) in filteredProblems.slice(0, Math.ceil(filteredProblems.length / 2))"
                    class="btn thumbnail-btn"
                    :class="{
                    'btn-primary': currentProblemIndex === index + numberProblems1*(currentSection-1),
                    'btn-outline-primary': currentProblemIndex !== index + numberProblems1*(currentSection-1) && problem.userAnswer === undefined,
                    'btn-success': currentProblemIndex !== index + numberProblems1*(currentSection-1) && problem.userAnswer !== undefined
                    }"
                    :key="index"
                    @click="goToProblem(index)"
                >
                    {{ index + 1 + numberProblems1*(currentSection-1)}}
                </button>
                </div>
                <div class="col-12 d-flex justify-content-center">
                <button
                    v-for="(problem, index) in filteredProblems.slice(Math.ceil(filteredProblems.length / 2))"
                    class="btn thumbnail-btn"
                    :class="{
                    'btn-primary': currentProblemIndex === index + Math.ceil(filteredProblems.length / 2) + numberProblems1*(currentSection-1),
                    'btn-outline-primary': currentProblemIndex !== index + Math.ceil(filteredProblems.length / 2) + numberProblems1*(currentSection-1) && problem.userAnswer === undefined,
                    'btn-success': currentProblemIndex !== index + Math.ceil(filteredProblems.length / 2) + numberProblems1*(currentSection-1) && problem.userAnswer !== undefined
                    }"
                    :key="index + Math.ceil(filteredProblems.length / 2)"
                    @click="goToProblem(index + Math.ceil(filteredProblems.length / 2))"
                >
                    {{ index + 1 + Math.ceil(filteredProblems.length / 2) + numberProblems1*(currentSection-1)}}
                </button>
                </div>
            </div>
            </div>
            <div class="thumbnails-container" v-if="currentProblem && !isLoading && isMobile()">
              <div class="d-flex justify-content-between">
                <button class="btn btn-secondary thumbnail-btn" @click="scrollProblems(-1)">&lt;</button>
                <div class="row" style="width: 60%;">
                  <div class="col-12 d-flex justify-content-center">
                    <button
                      v-for="(problem, index) in displayedProblems"
                      class="btn thumbnail-btn"
                      :class="{
                      'btn-primary': currentProblemIndex === index + this.startMobileThumbnail+ numberProblems1*(currentSection-1),
                      'btn-outline-primary': currentProblemIndex !== index + this.startMobileThumbnail + numberProblems1*(currentSection-1) && problem.userAnswer === undefined,
                      'btn-success': currentProblemIndex !== index + this.startMobileThumbnail + numberProblems1*(currentSection-1) && problem.userAnswer !== undefined
                      }"
                      :key="index"
                      @click="goToProblem(index + this.startMobileThumbnail)"
                    >
                      {{ index + 1 + this.startMobileThumbnail + numberProblems1*(currentSection-1)}}
                    </button>
                  </div>
                </div>
                <button class="btn btn-secondary thumbnail-btn" @click="scrollProblems(1)">&gt;</button>
              </div>
            </div>
          </div>
          <div
  v-if="currentSection === 3 && isDataLoaded === true && !isMobile()"
  class="d-flex flex-column justify-content-between"
  style="height: 80%"
>
  <!-- Title -->
  <div class="results-title text-center mb-3">
    <h2>Resultados</h2>
  </div>

  <!-- PROGRESS CONTAINER -->
  <div class="progress-container mb-3">
    <div class="d-flex" style="width: 100%;">
      <!-- LEFT COLUMN (30% width) -->
      <div class="d-flex flex-column" style="width: 30%;">
        
        <!-- Row 1: item #1 (index 0) -->
        <!-- Use flex-nowrap so they don't wrap (though there's only 1 item here). -->
        <div
          class="d-flex flex-nowrap justify-content-center mb-3"
          style="overflow-x: auto;"
        >
          <div
            v-for="key in leftColumnRow1"
            :key="key"
            class="mx-2"
          >
            <RadialProgress
              :key="key"
              :totalSteps="stepsLabel[key]"
              :completedSteps="limitedScoreReport[key]"
              :startColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :stopColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :showProgress="true"
              :text="`${key}: ${limitedScoreReport[key]}`"
              :diameter="120"
              :strokeWidth="10"
              :innerStrokeColor="'#ECECEC'"
            >
              <h3>{{ limitedScoreReport[key] }}</h3>
              <p>{{ formatLabel(key) }}</p>
            </RadialProgress>
          </div>
        </div>

        <!-- Row 2: items #2 and #3 (indexes 1,2) -->
        <div
          class="d-flex flex-nowrap justify-content-center"
          style="overflow-x: auto;"
        >
          <div
            v-for="key in leftColumnRow2"
            :key="key"
            class="mx-2"
          >
            <RadialProgress
              :key="key"
              :totalSteps="stepsLabel[key]"
              :completedSteps="limitedScoreReport[key]"
              :startColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :stopColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :showProgress="true"
              :text="`${key}: ${limitedScoreReport[key]}`"
              :diameter="120"
              :strokeWidth="10"
              :innerStrokeColor="'#ECECEC'"
            >
              <h3>{{ limitedScoreReport[key] }}</h3>
              <p>{{ formatLabel(key) }}</p>
            </RadialProgress>
          </div>
        </div>
      </div>
      <!-- END LEFT COLUMN -->

      <!-- RIGHT COLUMN (70% width) -->
      <div class="d-flex flex-column" style="width: 70%;">
        
        <!-- Row 1: items #4..#8 (indexes 3..7) -->
        <div
          class="d-flex flex-nowrap justify-content-center mb-3"
          style="overflow-x: auto;"
        >
          <div
            v-for="key in rightColumnRow1"
            :key="key"
            class="mx-2"
          >
            <RadialProgress
              :key="key"
              :totalSteps="stepsLabel[key]"
              :completedSteps="limitedScoreReport[key]"
              :startColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :stopColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :showProgress="true"
              :text="`${key}: ${limitedScoreReport[key]}`"
              :diameter="120"
              :strokeWidth="10"
              :innerStrokeColor="'#ECECEC'"
            >
              <h3>{{ limitedScoreReport[key] }}</h3>
              <p>{{ formatLabel(key) }}</p>
            </RadialProgress>
          </div>
        </div>

        <!-- Row 2: items #9..#12 (indexes 8..11) -->
        <div
          class="d-flex flex-nowrap justify-content-center"
          style="overflow-x: auto;"
        >
          <div
            v-for="key in rightColumnRow2"
            :key="key"
            class="mx-2"
          >
            <RadialProgress
              :key="key"
              :totalSteps="stepsLabel[key]"
              :completedSteps="limitedScoreReport[key]"
              :startColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :stopColor="colorByScore(100 * limitedScoreReport[key] / stepsLabel[key])"
              :showProgress="true"
              :text="`${key}: ${limitedScoreReport[key]}`"
              :diameter="120"
              :strokeWidth="10"
              :innerStrokeColor="'#ECECEC'"
            >
              <h3>{{ limitedScoreReport[key] }}</h3>
              <p>{{ formatLabel(key) }}</p>
            </RadialProgress>
          </div>
        </div>

      </div>
      <!-- END RIGHT COLUMN -->
    </div>
  </div>
  <!-- END PROGRESS CONTAINER -->

  <!-- Time metrics, button, etc. -->
  <div class="completion-time-title text-center mb-3">
    <h4>Tiempo para completar</h4>
  </div>
  <div class="time-metrics-container d-flex justify-content-between mb-3">
    <div class="time-metric text-center">
      <strong>Sección 1:</strong> {{ formatTime(timeMetrics.section1[1]) }} min
    </div>
    <div class="time-metric text-center">
      <strong>Sección 2:</strong> {{ formatTime(timeMetrics.section2[1]) }} min
    </div>
  </div>
  <div class="go-back-container text-center mb-3">
    <button class="btn btn-primary" @click="goBackToDashboard">
      Regresar
    </button>
  </div>
</div>

          <div v-if="currentSection === 3 && isDataLoaded==true && isMobile()" class="d-flex flex-column justify-content-between" style="height: 80%">
            <div class="results-title text-center mb-3">
                <h2>Resultados</h2>
            </div>
            <div class="progress-item" style="width: 100%; max-width: 100%;" v-for="(score, label) in limitedScoreReport" :key="label">        
        <div class="row">
          <div class="col" style="width: 50%;">
            <p style="text-align: left; margin-left: 20px;">{{formatLabel(label)}}</p>
          </div>
          <div class="col" style="width: 50%; margin-right: 20px;">
            <div class="progress" style="width: 100%; height: 10px; border-radius: 50px;">
    <div class="progress-bar" role="progressbar" :style="{ width: `${(score/stepsLabel[label])*100}%`, 'background-color': colorByScore(100*score/stepsLabel[label]), height: '10px', 'border-radius': '50px' }" :aria-valuenow="score" aria-valuemin="0" :aria-valuemax="stepsLabel[label]"></div>
</div>

        <p>{{score}} / {{stepsLabel[label]}}</p>

          </div>
        </div>
        </div>
        <div class="progress-item" style="width: 100%; max-width: 100%;">
          <div class="row">
              <div class="col" style="width: 50%; text-align: left; margin-left: 20px;">
                <strong>Prueba 1</strong>
              </div>
              <div class="col" style="width:50%; text-align: left;">
                <p>{{ formatTime(timeMetrics.section1[1]) }} minutos</p>
              </div>
            </div>
        </div>
        <div class="progress-item" style="width: 100%; max-width: 100%;">
          <div class="row">
              <div class="col" style="width: 50%; text-align: left; margin-left: 20px;">
                <strong>Prueba 2</strong>
              </div>
              <div class="col" style="width: 50%; text-align: left;">
                <p>{{ formatTime(timeMetrics.section2[1]) }} minutos</p>
              </div>
            </div>
        </div>
            <div class="go-back-container text-center mb-3">
                <button class="btn btn-primary" @click="goBackToDashboard">
                Regresar
                </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>


<script>
import axios from 'axios';
import { mapState } from 'vuex';
import RadialProgress from "vue3-radial-progress";
import FormattedText from "@/components/FormattedText.vue";




export default {

    components: {
        RadialProgress,
        FormattedText,
    },

    data() {
    return {
      scoreMapping: [],
      startMobileThumbnail: 0,
      timeRemaining: '',
      timer: null,
      problems: [],
      options:[],
      selectedOption:'',
      userAnswer: '',
      currentSection: 0,
      currentProblemIndex: 0,
      isLoading: false,
      totalProblems: 0,
      numberProblems1: 45,
      numberProblems2: 25, 
      userName: '',
      userId: '',
      startTime: '',
      endTime: '',
      mockTestId: 0,
      p5Instance: null,
      has_figure: 0,
      figureCode: '',
      setup: ``,
      draw: '',
      scoreReport: {
      Total: 0,
      Prueba1: 0,
      Prueba2: 0,
      Divulgacion: 0,
      Narrativo: 0,
      Lirico: 0,
      Graficas: 0,
      Comparado: 0,
      Elision: 0,
      Adicion: 0,
      Integracion: 0,
      Particularizacion: 0,
      Generalizacion: 0,
      Time_1: 0,
      Time_2: 0,
      Created_at: ''
    },
      stepsLabel:{
        Total: 800,
        Prueba1: 400,
        Prueba2: 400,
        Divulgacion: 100,
        Narrativo: 100,
        Lirico: 100,
        Graficas: 100,
        Comparado: 100,
        Elision: 100,
        Adicion: 100,
        Integracion: 100,
        Particularizacion: 100,
        Generalizacion: 100,
      },
      isDataLoaded: false,
      time_left_1: 0,
      time_left_2: 0,
      timeLeft: 0,
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
    };
  },

  computed: {

    displayedProblems() {
    if(this.startMobileThumbnail==44 && this.currentSection==1){
      return this.filteredProblems.slice(this.startMobileThumbnail, this.startMobileThumbnail+1);
    } else {
      return this.filteredProblems.slice(this.startMobileThumbnail, this.startMobileThumbnail + 3);
    }
    
  },

    timeMetrics() {
    const entries = Object.entries(this.scoreReport);
    return {
      section1: entries[13],
      section2: entries[14],
    };
  },

    limitedScoreReport() {
    return Object.entries(this.scoreReport)
      .slice(0, 13)
      .reduce((obj, [key, value]) => {
        obj[key] = value;
        return obj;
      }, {});
  },

  scoreKeys() {
      return Object.keys(this.limitedScoreReport);
    },

      // Left Column
  leftColumnRow1() {
    // indices [0] -> slice(0,1)
    return this.scoreKeys.slice(0, 1); 
  },
  leftColumnRow2() {
    // indices [1,2] -> slice(1,3)
    return this.scoreKeys.slice(1, 3);
  },

  // Right Column
  rightColumnRow1() {
    // indices [3,4,5,6,7] -> slice(3,8)
    return this.scoreKeys.slice(3, 8);
  },
  rightColumnRow2() {
    // indices [8,9,10,11] -> slice(8, 12);
    return this.scoreKeys.slice(8, 13);
  },

    filteredProblems() {
    if (this.currentSection === 1) {
      return this.problems.slice(0, this.numberProblems1);
    } else if (this.currentSection === 2) {
      return this.problems.slice(this.numberProblems1, this.numberProblems1 + this.numberProblems2);
    } else {
      return [];
    }
  },

  startIndex() {
    if (this.currentSection === 1) {
      return 0;
    } else if (this.currentSection === 2) {
      return this.numberProblems1;
    } else {
      return -1;
    }
  },

    currentProblem() {
    return this.problems[this.currentProblemIndex] || null;
  },

    timerStyle() {
    const timeRemainingInMinutes = parseInt(this.timeRemaining.split(':')[0]);
    return timeRemainingInMinutes < 5 ? 'timer-red' : '';
  },

    ...mapState(['email']),

    allProblemsAnswered() {
    let problemsCount;
    if (this.currentSection === 1) {
      problemsCount = this.numberProblems1;
    } else if (this.currentSection === 2) {
      problemsCount = this.numberProblems1+this.numberProblems2; // Total number of problems in both sections
    }

    let answeredProblems = 0;
    for (let i = 0; i < problemsCount; i++) {
      if (this.problems[i].userAnswer !== undefined) {
        answeredProblems += 1;
      }
    }

    return answeredProblems === problemsCount;
  },
},

mounted() {
  

  this.preventScreenshotShortcuts();


  this.loadScoreMapping();
  // Fetch the user data
  axios
    .get(`${this.$baseURL}/api/users/user-data`, {
      params: {
        email: this.email,
      },
    })
    .then((response) => {
      const data = response.data.data;
      this.userName = data.name;
      this.userId= data.id;
      this.createMockTest();
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
},

    methods: {

      startIndexMobile() {
        this.startMobileThumbnail=Math.floor(this.currentProblemIndex / 3) * 3;
  },

      scrollProblems(offset) {
    const newIndex = this.startMobileThumbnail + offset * 3;
    if (newIndex >= 0 && newIndex < this.filteredProblems.length) {
      this.startMobileThumbnail = newIndex;
    }
  },

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

      formatLabel(label) {
        const labelMap = {
          'Prueba1': 'Prueba 1',
          'Prueba2': 'Prueba 2',
          'Divulgacion': 'Divulgación',
          'Narrativo': 'Narrativo',
          'Lirico': 'Lírico',
          'Graficas': 'Gráficas',
          'Comparado': 'Comparado',
          'Elision': 'Elisión',
          'Adicion': 'Adición',
          'Integracion': 'Integración',
          'Particularizacion': 'Particularización',
          'Generalizacion': 'Generalización'
        };
        return labelMap[label] || label;
      },

      preventScreenshotShortcuts() {
      document.addEventListener("keydown", this.disableShortcuts);
    },

    removePreventScreenshotShortcuts() {
      document.removeEventListener("keydown", this.disableShortcuts);
    },

    disableShortcuts(event) {
      const key = event.key.toLowerCase();
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const isCmdOrCtrl = (isMac && event.metaKey) || (!isMac && event.ctrlKey);

      if (
        (isCmdOrCtrl && (key === "s" || key === "p" || key === "f")) || // Disable Ctrl/Cmd + S, P, and F
        (key === "printscreen") || // Disable Print Screen key
        (event.altKey && key === "printscreen") || // Disable Alt + Print Screen
        (isMac && event.shiftKey && event.ctrlKey && (key === "s" || key === "4")) // Disable Shift + Ctrl + S or 4 on Mac
      ) {
        event.preventDefault();
      }
    },

        goBackToDashboard() {
    this.$router.push('/language-student-dashboard');
  },

        async loadScoreMapping() {
    try {
      const response = await fetch('/parse-language-scores.json');
      const data = await response.json();
      this.scoreMapping = data;
    } catch (error) {
      console.error('Failed to load score mapping:', error);
    }
  },

  getScore(correctAnswers) {
    const mapping = this.scoreMapping.find((item) => item.correct_answers === correctAnswers);
    return mapping ? mapping.score : null;
  },

        goToProblem(index) {
    this.currentProblemIndex = this.startIndex + index;

    if(this.problems[this.currentProblemIndex].userAnswer != undefined){
        this.userAnswer = this.problems[this.currentProblemIndex].userAnswer;
        this.selectedOption = this.problems[this.currentProblemIndex].userAnswer;    
        } else{
        this.userAnswer = '';
        this.selectedOption = '';
        }
  },

        isSelected(index) {
    return this.currentProblem.userAnswer!=undefined && parseInt(this.currentProblem.userAnswer) === index;
  },

        previousProblem() {
        
    if (this.currentSection === 1) {
      if (this.currentProblemIndex > 0) {
        this.currentProblemIndex -= 1;
        if(this.problems[this.currentProblemIndex].userAnswer != undefined){
        this.userAnswer = this.problems[this.currentProblemIndex].userAnswer;
        this.selectedOption = this.problems[this.currentProblemIndex].userAnswer;    
        } else{
        this.userAnswer = '';
        this.selectedOption = '';
        }
      }
    } else if (this.currentSection === 2) {
      if (this.currentProblemIndex > this.numberProblems1) {
        this.currentProblemIndex -= 1;
        if(this.problems[this.currentProblemIndex].userAnswer != undefined){
        this.userAnswer = this.problems[this.currentProblemIndex].userAnswer;
        this.selectedOption = this.problems[this.currentProblemIndex].userAnswer;    
        } else{
        this.userAnswer = '';
        this.selectedOption = '';
        }
      }
    }

  },

  nextProblem() {
    if (this.currentProblemIndex < this.totalProblems - 1) {
      this.currentProblemIndex += 1;
    }
    if(this.problems[this.currentProblemIndex].userAnswer != undefined){
        this.userAnswer = this.problems[this.currentProblemIndex].userAnswer;
        this.selectedOption = this.problems[this.currentProblemIndex].userAnswer;    
        } else{
        this.userAnswer = '';
        this.selectedOption = '';
        }
  },

  nextSection() {
    this.userAnswer = '';
    this.selectedOption = '';
    this.time_left_1= 50*60-this.timeLeft;
    if (this.timer) {
        clearInterval(this.timer);
        }
    this.startSection(-1);
    this.currentProblemIndex=this.totalProblems;
  },

  finishTest() {
    this.time_left_2= 30*60 - this.timeLeft;
    if (this.timer) {
        clearInterval(this.timer);
        }
    this.startSection(-2);
  },

      startSection(section) {
        if(section==1){
            const now = new Date().toISOString().slice(0, 19).replace('T', ' ');
            this.startTime = now;
            this.updateMockTest(section, this.startTime);
        }
        if(section==-2){
            const now = new Date().toISOString().slice(0, 19).replace('T', ' ');
            this.endTime = now;
            this.updateMockTest(section, this.endTime);
        }
        if (this.timer) {
        clearInterval(this.timer);
        }
      this.currentSection = section;
      if(section>0){
      this.fetchDBProblem(this.currentSection);}
    },

    onSectionTimerFinished() {
      if (this.currentSection === 1) {
        this.startSection(-1); // Show start section 2 placeholder
        this.time_left_1=50*60;
      } else if (this.currentSection === 2) {
        this.startSection(-2); // Show see results placeholder
        this.time_left_2=30*60;
      }
    },
    async seeResults() {
    try{
    const response = await axios.get(`${this.$baseURL}/api/mock_tests/language-mock-test-data`, {
      params: {
        user_id: this.userId,
      },
    });

    const data = response.data.data;
    this.mockTestId = data.id;
     } catch(error){
        console.error('Error fetching mock test data:', error);
     }

      this.calculateResults();
      this.currentSection=3;
    },

// Assuming you're using Vue.js with Axios

async fetchDBProblem(currentSection) {
  this.isLoading = true;

  // Define categories for each section
  const categoriesSection1 = [
    'Divulgacion',
    'Narrativo',
    'Graficas',
    'Lirico',
    'Comparado'
  ];

  const categoriesSection2 = [
    'Elision',
    'Adicion',
    'Integracion',
    'Particularizacion',
    'Generalizacion'
  ];

  try {
    if (currentSection === 1) {
      // Section 1: Fetch 9 problems per category from a randomly selected sub_category
      for (const category of categoriesSection1) {
        // Step 1: Fetch sub_categories for the current category
        const subCatResponse = await axios.get(`${this.$baseURL}/api/get-subcategories`, {
          params: { category }
        });

        if (!subCatResponse.data.success) {
          console.error(`Failed to fetch sub_categories for category: ${category}`);
          continue; // Skip to the next category
        }

        const availableSubCategories = subCatResponse.data.data;
        if (availableSubCategories.length === 0) {
          console.warn(`No sub_categories available for category: ${category}`);
          continue;
        }

        // Step 2: Randomly select one sub_category
        const randomSubCategory = availableSubCategories[Math.floor(Math.random() * availableSubCategories.length)];

        // Step 3: Fetch 9 problems from the selected sub_category
        const problemsResponse = await axios.get(`${this.$baseURL}/api/get-multiple-problems`, {
          params: {
            sub_categories: JSON.stringify([randomSubCategory]),
            numbers: JSON.stringify([9]),
            is_open_answer: 0,
            user_id: this.userId,
            mock_test_id: this.mockTestId,
            topic: 'Lang - PAA'
          }
        });

        if (problemsResponse.data.success) {
          const fetchedProblems = problemsResponse.data.data;
          this.problems.push(...fetchedProblems);
          this.totalProblems += fetchedProblems.length;
        } else {
          console.error(`Failed to fetch problems for category: ${category}, sub_category: ${randomSubCategory}`);
        }
      }

    } else if (currentSection === 2) {
      // Section 2: Fetch 25 problems from 4 randomly selected sub_categories across 5 categories

      // Step 1: Fetch sub_categories for all 5 categories
      let allSubCategories = [];

      for (const category of categoriesSection2) {
        const subCatResponse = await axios.get(`${this.$baseURL}/api/get-subcategories`, {
          params: { category }
        });

        if (!subCatResponse.data.success) {
          console.error(`Failed to fetch sub_categories for category: ${category}`);
          continue; // Skip to the next category
        }

        const subCategories = subCatResponse.data.data;
        if (subCategories.length > 0) {
          allSubCategories.push(...subCategories);
        }
      }

      // Remove duplicate sub_categories
      allSubCategories = [...new Set(allSubCategories)];

      if (allSubCategories.length < 4) {
        console.error('Not enough sub_categories available to select 4 unique ones.');
        // Handle accordingly, e.g., select all available sub_categories
      }

      // Step 2: Randomly select 4 sub_categories
      const shuffledSubCategories = allSubCategories.sort(() => 0.5 - Math.random());
      const selectedSubCategories = shuffledSubCategories.slice(0, 4);

      // Step 3: Determine a random distribution of 25 problems across 4 sub_categories
      const distribution = this.generateRandomDistribution(4, 25, 5, 8); // min 5, max 8 per sub_category

      if (!distribution) {
        console.error('Failed to generate a valid distribution of problems.');
        // Handle accordingly, e.g., retry or use a default distribution
      }

      // Step 4: Prepare sub_categories and numbers arrays
      const subCatsForFetching = selectedSubCategories;
      const numbersForFetching = distribution;

      // Step 5: Fetch problems for each selected sub_category
      const fetchParams = {
        sub_categories: JSON.stringify(subCatsForFetching),
        numbers: JSON.stringify(numbersForFetching),
        is_open_answer: 0,
        user_id: this.userId,
        mock_test_id: this.mockTestId,
        topic: 'Lang - PAA'
      };

      const problemsResponse = await axios.get(`${this.$baseURL}/api/get-multiple-problems`, {
        params: fetchParams
      });

      if (problemsResponse.data.success) {
        const fetchedProblems = problemsResponse.data.data;
        this.problems.push(...fetchedProblems);
        this.totalProblems += fetchedProblems.length;
      } else {
        console.error('Failed to fetch problems for section 2.');
      }
    }


  } catch (error) {
    console.error('Error fetching problems:', error);
  } finally {

    this.isLoading = false;
    this.startTimer(currentSection === 1 ? 50 : 30);
  }
},

/**
 * Generates a random distribution of total problems across a specified number of sub_categories.
 * Each sub_category must have at least minPerCategory and at most maxPerCategory problems.
 * The total must equal the targetTotal.
 * 
 * @param {number} numCategories - Number of sub_categories
 * @param {number} targetTotal - Total number of problems to distribute
 * @param {number} minPerCategory - Minimum number of problems per sub_category
 * @param {number} maxPerCategory - Maximum number of problems per sub_category
 * @returns {Array|null} - Array of numbers representing problems per sub_category or null if not possible
 */
generateRandomDistribution(numCategories, targetTotal, minPerCategory, maxPerCategory) {
  // Initialize with the minimum required per category
  let distribution = Array(numCategories).fill(minPerCategory);
  let remaining = targetTotal - minPerCategory * numCategories;

  // Distribute the remaining problems randomly, ensuring we don't exceed maxPerCategory
  while (remaining > 0) {
    let idx = Math.floor(Math.random() * numCategories);
    if (distribution[idx] < maxPerCategory) {
      distribution[idx]++;
      remaining--;
    }
    // To prevent infinite loops in case it's impossible to distribute
    if (remaining > 0 && distribution.every(count => count >= maxPerCategory)) {
      console.error('Unable to distribute problems within the specified constraints.');
      return null;
    }
  }

  // Shuffle the distribution to add variability
  for (let i = distribution.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [distribution[i], distribution[j]] = [distribution[j], distribution[i]];
  }

  return distribution;
},


startTimer(minutes) {
  // Clear any existing timer interval
  if (this.timer) {
    clearInterval(this.timer);
  }

  this.timeLeft = minutes * 60;

  this.timer = setInterval(() => {
    this.timeLeft -= 1;
    this.timeRemaining = this.formatTime(this.timeLeft);

    if (this.timeLeft <= 0) {
      clearInterval(this.timer);
      this.timer = null;
      this.onSectionTimerFinished();
    }
  }, 1000);
},

formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  },

  submitAnswer() {
  // Check if the timer has run out or if the user has already answered this question
  /* if (this.timeLeft <= 0 || this.problems[this.currentProblemIndex].userAnswer !== undefined) {
    return;
  } */
  if (this.timeLeft <= 0) {
    return;
  }

  // Retrieve the user's answer
  const save_userAnswer = this.getUserAnswer();

  // Store the user's answer in the answers object
  Object.assign(this.problems[this.currentProblemIndex], {userAnswer: save_userAnswer});

  if(this.currentProblemIndex<this.totalProblems-1){
    this.nextProblem();
  }
},

getUserAnswer() {
    if (this.currentProblemIndex < this.numberProblems1+this.numberProblems2) {
      return this.selectedOption.toString();
    } else {
      if (this.userAnswer!="" || this.userAnswer!=" " || this.userAnswer!=null){
        return this.userAnswer;
      } else {
        return "Sin respuesta"
      }
    }
  },

async calculateResults() {
  // Calculate section and category scores
  const section1Count = await this.calculateSectionCount(1);

  const section2Count = await this.calculateSectionCount(2);

  const categoryScores = await this.calculateCategoryScores();

  const totalScore = await this.getScore(section1Count + section2Count);

  const sectionScores = await this.calculateSectionScore(totalScore, section1Count, section2Count);


  // Store scores in the database
  await this.writeScoresToDatabase(totalScore, sectionScores, categoryScores);

  // Fetch score reports data
  axios
    .get(`${this.$baseURL}/api/users/language-user-data`, {
      params: {
        email: this.email,
      },
    })
    .then((response) => {
      const data = response.data.data;
      this.userName = data.name;
      this.scoreReport = data.scoreReport;
      this.isDataLoaded = true;
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });
  // Do something with the score reports data, e.g., display it on the screen
},

colorByScore(score) {
  const numericScore = Number(score);

  if (numericScore < 40) {
    return "#cc3232"; // red
  } else if (numericScore < 80) {
    return "#e7b416"; // yellow
  } else {
    return "#2dc937"; // green
  }
},

calculateSectionScore(totalScore, section1Count, section2Count){
    
    let section1Score = 0;
    let section2Score = 0;
    let sectionScores = {};

    if(section1Count+section2Count!=0){
        section1Score = totalScore*(section1Count/(section1Count+section2Count));

        section2Score = totalScore-section1Score;
    }
          

    sectionScores['section1_score']=section1Score;
    sectionScores['section2_score']=section2Score;


    return sectionScores;
},

async calculateSectionCount(section) {
  let score = 0;

  if (section === 1) {
    for (let i = 0; i < this.numberProblems1; i++) {
      if (this.problems[i].userAnswer === this.problems[i].Answer) {
        score++;
        await this.submitUserAnswers(this.problems[i],1);
      } else{
        await this.submitUserAnswers(this.problems[i],0);
      }
    }
  } else if (section === 2) {
    for (let i = this.numberProblems1; i < this.numberProblems1+this.numberProblems2; i++) {
      if (this.problems[i].userAnswer === this.problems[i].Answer) {
        score++;
        await this.submitUserAnswers(this.problems[i],1);
      } else{
        await this.submitUserAnswers(this.problems[i],0);
      }
    }
    for (let i = this.numberProblems1+this.numberProblems2; i < this.totalProblems; i++) {
      if (this.problems[i].userAnswer === this.problems[i].Answer || (this.problems[i].Variations.includes(this.problems[i].userAnswer)) && this.problems[i].userAnswer != "") {
        score++;
        await this.submitUserAnswers(this.problems[i],1);
      } else{
        await this.submitUserAnswers(this.problems[i],0);
      }
    }
  }

  return score;
},

calculateCategoryScores() {
  const categories = [
    'Divulgacion',
    'Narrativo',
    'Lirico',
    'Graficas',
    'Comparado',
    'Elision',
    'Adicion',
    'Particularizacion',
    'Generalizacion',
    'Integracion'];

  const categoryScores = {};
  const categoryCount = {};

  categories.forEach((category) => {
    categoryScores[category] = 0;
  });
  categories.forEach((category) => {
    categoryCount[category] = 0;
  });

  // Calculate scores for section 1
  for (let i = 0; i < this.numberProblems1+this.numberProblems2; i++) {
      categoryCount[this.problems[i].Category]++;
    if (this.problems[i].userAnswer === this.problems[i].Answer) {
      categoryScores[this.problems[i].Category]++;
    }
  }

  // Calculate scores for section 2
  for (let i = this.numberProblems1+this.numberProblems2; i < this.totalProblems; i++) {
      categoryCount[this.problems[i].Category]++;
    if (this.problems[i].userAnswer === this.problems[i].Answer || (this.problems[i].Variations.includes(this.problems[i].userAnswer)) && this.problems[i].userAnswer != "") {
      categoryScores[this.problems[i].Category]++;
    }
  }
  
  categories.forEach((category) => {
    if(categoryCount[category]!=0){
        categoryScores[category] = parseInt(100*categoryScores[category]/categoryCount[category]);
    }
  });
  

  return categoryScores;
},

async writeScoresToDatabase(totalScore, sectionScores, categoryScores) {
  // Create mock test in DB

  try {

    const scoreReport = {
      user_id: this.userId,
      mock_test_id: this.mockTestId,
      total_score: totalScore,
      section1_score: sectionScores['section1_score'],
      section2_score: sectionScores['section2_score'],
      divulgacion: categoryScores['Divulgacion'],
      narrativo: categoryScores['Narrativo'],
      lirico: categoryScores['Lirico'],
      grafico: categoryScores['Graficas'],
      comparado: categoryScores['Comparado'],
      elision: categoryScores['Elision'],
      adicion: categoryScores['Adicion'],
      particularizacion: categoryScores['Particularizacion'],
      generalizacion: categoryScores['Generalizacion'],
      integracion: categoryScores['Integracion'],
      section1_remaining_time: this.time_left_1,
      section2_remaining_time: this.time_left_2,
    };


    await axios.post(`${this.$baseURL}/api/write-language-score-report`, scoreReport);
  } catch (error) {
    console.error('Error creating scores report:', error);
  }
},

async submitUserAnswers(currentProblem,correct){
  console.log(currentProblem);

    try{
        const userMockTestAnswer = {
            user_id: this.userId,
            problem_id: currentProblem.ProblemID,
            mock_test_problem_id: currentProblem.MockID,
            user_answer: currentProblem.userAnswer,
            correct: correct

        }
        console.log(userMockTestAnswer);
        await axios.post(`${this.$baseURL}/api/submit-language-user-answers`, userMockTestAnswer);
    } catch(error) {
        console.error('Error submitting user answers');
    }

},

async updateMockTest(current_section, time_stamp) {
    try{
        const mockTestUpdate = {
            section: current_section,
            time_stamp: time_stamp,
            id: this.mockTestId
        }
        await axios.put(`${this.$baseURL}/api/update-mock-test`, mockTestUpdate);
    } catch(error) {
        console.error('Error updating mock test time stamps');
    }
},

async createMockTest() {
  return new Promise((resolve, reject) => {
    const createAndGetMockTest = async () => {
      try {
        const mockTest = {
          user_id: this.userId,
        };

        // Wait for the POST request to complete
        await axios.post(`${this.$baseURL}/api/create-language-mock-test`, mockTest);

        // Then send the GET request
        try {
          const response = await axios.get(`${this.$baseURL}/api/mock_tests/language-mock-test-data`, {
            params: {
              user_id: this.userId,
            },
          });

          const data = response.data.data;
          this.mockTestId = data.id;
          resolve();
        } catch (error) {
          console.error("Error fetching mock test data:", error);
          reject(error);
        }
      } catch (error) {
        console.error('Error creating mock test:', error);
        reject(error);
      }
    };

    createAndGetMockTest();
  });
}

    },
  };


</script>

<style>

.problem-image {
    width: auto;    /* Set the width of the image to 40% of the container */
    height: 250px;  /* Maintain the aspect ratio */
}

.container {
      height: 100vh;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }

.time-metrics-container {
  width: 100%;
  font-size: 40px;
  margin-top: 40px;
}

.time-metric {
  flex: 1;
}

.thumbnails-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .thumbnail-btn {
    margin: 5px;
    min-height: 45px;
    min-width: 45px;
    max-width: 45px;
    max-height: 45px;
    text-align: center;
  }


.selected-option {
  background-color: #e7edf0; /* Change the color to your preference */
  border-radius: 10px;
}

.timer-red {
  color: #cc3232;
}

.time-remaining-box {
  display: inline-flex;
  align-items: center;
  color: black;
  font-weight: bold;
  border-color: black;
  border-radius: 5px;
  font-size: 40px;
}

.submit-button-container, .buttons-right {
  display: flex;
  justify-content: flex-end;
}

.buttons-left {
  display: flex;
  justify-content: flex-start;
}


.next-section-button {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.progress-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  position: relative;
  flex-wrap: wrap;
  max-width: 100%;
  max-height: 100%;
  z-index: 1; /* This will make sure the progress bars are above the other elements */
}

.progress-item {
  width: 12.5%; /* Adjust this value based on how many items you want to fit in a row */
  max-width: 100%; /* Adjust this value based on your preferred max-width */
}

.card {
      background-color: white;
    }
</style>
