<template>
    <div class="container" @contextmenu.prevent>
      <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link to="../user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          </div>
      <div class="row">
        <div class="col-12">
          <div class="card" style="min-height: 80vh">
            <div class="card-body">
              <h1 class="card-title" style="margin-top: 20px; margin-bottom: 20px;">
                Número de Examen: {{ $route.params.mock_test_id }}
              </h1>
              <div v-if="currentSection === 1 || currentSection === 2">
                <h5 class="card-subtitle" style="margin-top: 20px; margin-bottom: 20px">
                  Problema {{ currentProblemIndex + 1 }} de {{ totalProblems }}
                </h5>
                <div class="problem-container">
                  <img v-show='currentProblem.has_figure==1' class="problem-image" :src="`https://mathpaas-documents.s3.amazonaws.com/images/${currentProblem.draw}.png`" alt="Assessment image">

                  <div v-if="isLoading" class="loading-animation">
                    <div class="spinner-border text-primary" role="status">
                      <span class="visually-hidden">Cargando...</span>
                    </div>
                  </div>
                  <template v-else>
                    <div 
                    :class="!isMobile() ? 'formatted-text-wrapper' : 'formatted-text-wrapper-mobile'">
                      <FormattedText :content="currentProblem.problem"/>
                    </div>
                    <div class="options" style="margin-top: 30px;"
                    v-if="currentProblemIndex < numberProblems1+numberProblems2">
                      <div
                        class="form-check"
                        v-for="(option, index) in currentProblem.OptionLabels"
                        :key="index"
                        :class="isSelected(index)"
                      >
                        <input
                          class="form-check-input"
                          type="radio"
                          name="option"
                          :id="'option' + index"
                          :value="index"
                          v-model="selectedOption"
                          disabled
                        />
                        <label
                          style="margin-right: 10px;"
                          class="form-check-label"
                          :for="'option' + index"
                        ><div>
                      <FormattedText :content="option.label + '. ' + option.text"/>
                    </div></label>
                      </div>
                    </div>
                    <div v-else class="answer-input" style="margin-top: 30px;">
                      <div class="form-group">
                        <input
                            type="text"
                            class="form-control"
                            id="userAnswer"
                            v-model="userAnswer"
                            placeholder="Sin respuesta"
                            disabled
                            :class="textBoxBackgroundColor"
                            />
                      </div>
                    </div>
                  </template>
                  <div class="buttons d-flex flex-column" v-if="!isLoading && !isMobile()">
                    <div class="submit-button-container mb-3">
                        <button class="btn btn-info equal-width text-white" @click="showHintInModal">Pistas ({{ this.problems[currentProblemIndex].remainingHints }})</button>
                        <button class="btn btn-secondary equal-width" @click="showExplanationInModal">Ayuda</button>
                    </div>
                    <div class="d-flex justify-content-between align-items-center" style="width: 100%;">
                      <button
                        class="btn btn-secondary equal-width"
                        @click="previousProblem"
                      >
                        Anterior
                      </button>
                      <button class="btn btn-primary" @click="toggleSection">
                  {{ currentSection === 1 ? "Ver Seccion 2" : "Ver Seccion 1" }}
                    </button>
                      <button
                        class="btn btn-secondary equal-width"
                        @click="nextProblem"
                      >
                        Siguiente
                      </button>
                    </div>
                  </div>
                  <div class="buttons d-flex flex-column " style="width: 100%;" v-if="!isLoading && isMobile()">
                    <div >
                        <button style="width: 45%; height: 40px;" class="btn btn-info equal-width text-white" @click="showHintInModal">Pistas ({{ this.problems[currentProblemIndex].remainingHints }})</button>
                        <button style="width: 45%; height: 40px;" class="btn btn-secondary equal-width" @click="showExplanationInModal">Ayuda</button>
                    </div>
                    <div  style="width: 100%; margin-top: 20px;">
                      <button
                        class="btn btn-secondary equal-width" style="width: 45%; height: 40px;"
                        @click="previousProblem"
                      >
                        Anterior
                      </button>
                      <button
                        class="btn btn-secondary equal-width" style="width: 45%; height: 40px "
                        @click="nextProblem"
                      >
                        Siguiente
                      </button>
                    </div>
                    <div style="width: 100%; margin-top: 20px;">
                      <button class="btn btn-primary" @click="toggleSection" style="width: 60%; height: 40px;">
                  {{ currentSection === 1 ? "Ver Seccion 2" : "Ver Seccion 1" }}
                    </button>
                    </div>
                  </div>
                </div>
                <div class="thumbnails-container" v-if="currentProblem && !isLoading && !isMobile()">
                  <div class="row" style="width: 60%;">
                    <div class="col-12 d-flex justify-content-center">
                    <button
                    v-for="(problem, index) in filteredProblems.slice(0, Math.ceil(filteredProblems.length / 2))"
                    class="btn thumbnail-btn"
                    :class="{
                    'btn-primary': currentProblemIndex === index + numberProblems1*(currentSection-1),
                    'btn-outline-primary': currentProblemIndex !== index + numberProblems1*(currentSection-1),
                    }"
                    :key="index"
                    @click="goToProblem(index)"
                    >
                    {{ index + 1 + numberProblems1*(currentSection-1)}}
                    </button>
                    </div>
                    <div class="col-12 d-flex justify-content-center">
                    <button
                    v-for="(problem, index) in filteredProblems.slice(Math.ceil(filteredProblems.length / 2))"
                    class="btn thumbnail-btn"
                    :class="{
                    'btn-primary': currentProblemIndex === index + Math.ceil(filteredProblems.length / 2) + numberProblems1*(currentSection-1),
                    'btn-outline-primary': currentProblemIndex !== index + Math.ceil(filteredProblems.length / 2) + numberProblems1*(currentSection-1),
                    }"
                    :key="index + Math.ceil(filteredProblems.length / 2)"
                    @click="goToProblem(index + Math.ceil(filteredProblems.length / 2))"
                    >
                    {{ index + 1 + Math.ceil(filteredProblems.length / 2) + numberProblems1*(currentSection-1)}}
                    </button>
                    </div>
                    </div>
                    </div>
                    <div class="thumbnails-container" v-if="currentProblem && !isLoading && isMobile()">
              <div class="d-flex justify-content-between">
                <button class="btn btn-secondary thumbnail-btn" @click="scrollProblems(-1)">&lt;</button>
                <div class="row" style="width: 60%;">
                  <div class="col-12 d-flex justify-content-center">
                    <button
                      v-for="(problem, index) in displayedProblems"
                      class="btn thumbnail-btn"
                      :class="{
                      'btn-primary': currentProblemIndex === index + this.startMobileThumbnail+ numberProblems1*(currentSection-1),
                      'btn-outline-primary': currentProblemIndex !== index + this.startMobileThumbnail + numberProblems1*(currentSection-1) && problem.userAnswer === undefined,
                      'btn-success': currentProblemIndex !== index + this.startMobileThumbnail + numberProblems1*(currentSection-1) && problem.userAnswer !== undefined
                      }"
                      :key="index"
                      @click="goToProblem(index + this.startMobileThumbnail)"
                    >
                      {{ index + 1 + this.startMobileThumbnail + numberProblems1*(currentSection-1)}}
                    </button>
                  </div>
                </div>
                <button class="btn btn-secondary thumbnail-btn" @click="scrollProblems(1)">&gt;</button>
              </div>
            </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>
                    <div class="modal fade" id="resultModal" tabindex="-1" aria-labelledby="resultModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="resultModalLabel">{{ modalTitle }}</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div v-if="!isChatMode">
          <FormattedText :content="modalMessage"/>
        </div>
        <div v-else class="chat-container">
          <div class="messages">
            <div v-for="(message, index) in chatMessages" :key="index" class="message">
              <div :class="message.sender === 'user' ? 'user-message' : 'assistant-message'">
                <FormattedText :content="message.content"/>
              </div>
            </div>
            <div v-if="isWriting" class="message">
                <div class="assistant-message" style="width: 60px; height: 40px;">
                <div class="loader" style="margin-top: -10px;"></div>
                </div>
            </div>
          </div>
          <div class="input-area">
            <input v-model="userInput" @keyup.enter="sendMessage" type="text" class="form-control" placeholder="Type your message here...">
            <button @click="sendMessage" class="btn btn-primary">Enviar</button>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-secondary me-auto" @click="backToDashboard" data-bs-dismiss="modal">Back to Dashboard</button> -->
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="closeModal" v-if="showContinueButton">Continuar</button>
        <button type="button" class="btn btn-primary" @click="fetchAndCloseModal" v-if="showNextProblemButton">Siguiente</button>
        <button type="button" class="btn btn-primary" @click="showExplanationAfterHints" v-if="showExplanationButton">Ayuda</button>  
    </div>
    </div>
  </div>
                    </div>
                </div>
                    
</template>
  
  
  <script>
  import axios from 'axios';
  //import { mapState } from 'vuex';
  import FormattedText from "@/components/FormattedText.vue";
  import { Modal } from "bootstrap";


  export default {

    name: "PreviousExam",

    components: {
        FormattedText,
    },

    created() {
  this.fetchProblemsByMockTestId(this.$route.params.mock_test_id).then(() => {
    this.setSelectedOptionAndUserAnswer();
  });
    },

    computed: {

      displayedProblems() {
    if(this.startMobileThumbnail==44 && this.currentSection==1){
      return this.filteredProblems.slice(this.startMobileThumbnail, this.startMobileThumbnail+1);
    } else {
      return this.filteredProblems.slice(this.startMobileThumbnail, this.startMobileThumbnail + 3);
    }
    
  },

        textBoxBackgroundColor() {
  return this.currentProblem.correct === 1 ? "text-correct" : "text-wrong";
},

filteredProblems() {
if (this.currentSection === 1) {
  return this.problems.slice(0, this.numberProblems1);
} else if (this.currentSection === 2) {
  return this.problems.slice(this.numberProblems1, this.numberProblems1 + this.numberProblems2);
} else {
  return [];
}
},

startIndex() {
if (this.currentSection === 1) {
  return 0;
} else if (this.currentSection === 2) {
  return this.numberProblems1;
} else {
  return -1;
}
},

currentProblem() {
return this.problems[this.currentProblemIndex] || null;
},


},

    data() {
    return {
      problems: [],
      startMobileThumbnail: 0,
      selectedOption:'',
      userAnswer: '',
      currentSection: 1,
      currentProblemIndex: 0,
      isLoading: false,
      totalProblems: 0,
      numberProblems1: 45,
      numberProblems2: 25, 
      isDataLoaded: false,
      time_left_1: 0,
      time_left_2: 0,
      timeLeft: 0,
      p5Instance: null,
      has_figure: 0,
      figureCode: '',
      setup: ``,
      draw: '',
      modalTitle: '',
        modalMessage: '',
        showContinueButton: false,
        showExplanationButton: false,
        showNextProblemButton: false,
        shouldFetchNewProblem: false,
        isChatMode: false,
        userInput: '',
        chatMessages: [],
        isWriting: false,
        userImage: require('@/assets/user.png'),
        HeaderLogo: require('@/assets/Mathpaas-white.png')
    };
  },

  mounted() {
  

  this.preventScreenshotShortcuts();
},


    methods: {

      preventScreenshotShortcuts() {
      document.addEventListener("keydown", this.disableShortcuts);
    },

    removePreventScreenshotShortcuts() {
      document.removeEventListener("keydown", this.disableShortcuts);
    },

    disableShortcuts(event) {
      const key = event.key.toLowerCase();
      const isMac = navigator.platform.toUpperCase().indexOf("MAC") >= 0;
      const isCmdOrCtrl = (isMac && event.metaKey) || (!isMac && event.ctrlKey);

      if (
        (isCmdOrCtrl && (key === "s" || key === "p" || key === "f")) || // Disable Ctrl/Cmd + S, P, and F
        (key === "printscreen") || // Disable Print Screen key
        (event.altKey && key === "printscreen") || // Disable Alt + Print Screen
        (isMac && event.shiftKey && event.ctrlKey && (key === "s" || key === "4")) // Disable Shift + Ctrl + S or 4 on Mac
      ) {
        event.preventDefault();
      }
    },

    scrollProblems(offset) {
    const newIndex = this.startMobileThumbnail + offset * 3;
    if (newIndex >= 0 && newIndex < this.filteredProblems.length) {
      this.startMobileThumbnail = newIndex;
    }
  },

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

        async fetchGPTResponse() {

this.isWriting = true;

try {
  const conversation = this.chatMessages;
  const problem = this.problem;
  const response = await axios.get(`${this.$baseURL}/api/generate-response`, {
    params: { conversation, problem },
  });

  if (response.data.success) {
      this.isWriting = false;

      return response.data.response;

  } else {
    throw new Error('Error fetching GPTResponse');
  }
} catch (error) {
  console.error(error);
}
},

        async sendMessage() {
    if (this.userInput.trim() === '') return;
    this.chatMessages.push({ sender: 'user', content: this.userInput });
    
    const gptPrompt = this.chatMessages;
    this.userInput = '';

    const gptResponse = await this.fetchGPTResponse(gptPrompt.response);
    this.chatMessages.push({ sender: 'assistant', content: gptResponse });
  },

        closeModal() {
      const modal = Modal.getInstance(document.getElementById("resultModal"));
      modal.hide();
      const body = document.getElementsByTagName("body")[0];
      body.classList.remove("modal-open");
    },

    showModal(title, message) {
    this.modalTitle = title;
    this.modalMessage = message;
    const modal = new Modal(document.getElementById("resultModal"));
    modal.show();
  },

  fetchAndCloseModal() {
    const modal = Modal.getInstance(document.getElementById("resultModal"));
    modal.hide();
    this.nextProblem();
  },

  showExplanationAfterHints(){
    const modal = Modal.getInstance(document.getElementById("resultModal"));
    modal.hide();
    this.showExplanationInModal();
  },

    showExplanationInModal() {
    this.chatMessages=[],
    this.isChatMode = true;
    this.modalTitle = "Asistente"
    this.showModal(this.modalTitle, this.problems[this.currentProblemIndex].explanation);
    this.showNextProblemButton = true;
    this.showExplanationButton = false;
    this.showContinueButton = false;
  this.chatMessages.push({ sender: 'assistant', content: this.problems[this.currentProblemIndex].explanation });
  this.chatMessages.push({ sender: 'assistant', content: 'Tienes alguna duda?' });
},

showHintInModal() {
    this.isChatMode = false;
  if (this.problems[this.currentProblemIndex].remainingHints == 0) {
    this.showModal("Pista", "Ya no hay mas pistas. Da clic en el boton de ayuda para recibir una explicacion detallada de nuestro asistente virtual.");
    this.showNextProblemButton = false;
    this.showExplanationButton = true;
    this.showContinueButton = true;
  } else {
    this.showModal("Pista", this.problems[this.currentProblemIndex].Hints[this.problems[this.currentProblemIndex].Hints.length - this.problems[this.currentProblemIndex].remainingHints]);
    this.problems[this.currentProblemIndex].remainingHints -= 1;
    this.showNextProblemButton = false;
    this.showExplanationButton = false;
    this.showContinueButton = true;
  }
},

setSelectedOptionAndUserAnswer() {
  if (this.problems[this.currentProblemIndex].user_answer != undefined) {
    this.userAnswer = this.problems[this.currentProblemIndex].user_answer;
    this.selectedOption = this.problems[this.currentProblemIndex].user_answer;
  } else {
    this.userAnswer = '';
    this.selectedOption = '';
  }
},

  async fetchProblemsByMockTestId(mock_test_id) {
  this.isLoading = true;

  try {
    const response = await axios.get(`${this.$baseURL}/api/get-problems-by-mock-test-id`, {
      params: { mock_test_id },
    });

    const jsonObjectArray = response.data.data;
    this.totalProblems = jsonObjectArray.length;

    jsonObjectArray.forEach(jsonObject => {
    const tempHints = JSON.parse(jsonObject.hints);
    const remainingHints = tempHints.length;
    Object.assign(jsonObject, { Hints: tempHints});
    Object.assign(jsonObject, { remainingHints: remainingHints});
  if (jsonObject.options != null) {
    const tempOptions = JSON.parse(jsonObject.options);
    const optionLabels = Array.from({ length: tempOptions.length }, (_, i) => String.fromCharCode(65 + i));
    const options = tempOptions.map((option, index) => {
      return {
        label: optionLabels[index],
        text: option,
      };
    });
    // Add OptionLabels property to jsonObject
    Object.assign(jsonObject, { OptionLabels: options });
  }
  this.problems.push(jsonObject);
});

  } catch (error) {
    console.error('Error fetching problems by mock_test_id:', error);
  } finally {
    this.isLoading = false;
  }
},


      toggleSection() {
    if (this.currentSection === 1) {
      this.currentSection = 2;
      this.startMobileThumbnail=0;
      this.currentProblemIndex = this.numberProblems1;
      this.setSelectedOptionAndUserAnswer();
    } else {
      this.currentSection = 1;
      this.currentProblemIndex = 0;
      this.startMobileThumbnail=0;
      this.setSelectedOptionAndUserAnswer();
    }
    
  },

    goToProblem(index) {
  this.currentProblemIndex = this.startIndex + index;
  this.setSelectedOptionAndUserAnswer();
},

      isSelected(index) {
  const correct = this.currentProblem.correct === 1;
  const selected = this.currentProblem.user_answer!=undefined && parseInt(this.currentProblem.user_answer) === index;
  return {
    'selected-correct': correct && selected,
    'selected-wrong': !correct && selected,
  };
},

nextProblem() {
  if (this.currentProblemIndex < this.totalProblems - 1) {
    this.currentProblemIndex += 1;

    if (this.currentSection === 1 && this.currentProblemIndex === this.numberProblems1) {
        this.currentSection = 2;
    }
  } this.setSelectedOptionAndUserAnswer();
},

previousProblem() {
    if (this.currentProblemIndex > 0) {
      this.currentProblemIndex -= 1;

      if (this.currentSection === 2 && this.currentProblemIndex === this.numberProblems1 - 1) {
        this.currentSection = 1;
    }
} this.setSelectedOptionAndUserAnswer();

},


},
  };
  </script>
  
  <style scoped>

.problem-image {
    width: auto;    /* Set the width of the image to 40% of the container */
    height: 250px;  /* Maintain the aspect ratio */
}
  .container {
      height: 100vh;
      user-select: none;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
    }
  .buttons-right {
  display: flex;
  justify-content: flex-end;
}

.buttons-left {
  display: flex;
  justify-content: flex-start;
}


.selected-correct {
  background-color: #c9e9cf; /* Change the color to your preference */
  border-radius: 10px;
}

.selected-wrong {
  background-color: #f5b2b2; /* Change the color to your preference */
  border-radius: 10px;
}

.text-correct {
  background-color: #c9e9cf !important; /* Change the color to your preference */
}

.text-wrong {
  background-color: #f5b2b2 !important; /* Change the color to your preference */
}

.thumbnails-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 20px;
  }

  .thumbnail-btn {
    margin: 5px;
    min-height: 45px;
    min-width: 45px;
    max-width: 45px;
    max-height: 45px;
    text-align: center;
  }

  .card {
      background-color: white;
    }

  </style>