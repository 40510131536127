<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <router-link to="user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
        </div>
        <!-- Greeting row with button on the right -->
        <div class="greeting-container position-relative">
          <!-- Centered greeting -->
          <h1 class="greeting text-center mx-auto">¡Hola, {{ userName }}!</h1>

          <!-- Button on the right (absolute) shown only if NOT mobile -->
          <button
            v-if="!isMobile()"
            class="btn btn-primary position-absolute language-btn"
            @click="goToLanguageStudentDashboard"
          >
            Prueba de Aptitudes de Matemáticas
          </button>
        </div>

        <!-- Button below greeting (normal flow) shown only if mobile -->
        <div v-if="isMobile()" class="text-center mt-3">
          <button
            class="btn btn-primary"
            @click="goToLanguageStudentDashboard"
          >
            Ir a Prueba de Aptitudes de Matemáticas
          </button>
        </div>
        <!-- NON-MOBILE SCORE SECTION -->
        <div
          class="card greeting-card"
          style="width: 100%; min-height: 15vh; margin-top: 20px;"
          v-if="!isMobile()"
        >
          <div class="card-body">
            <!-- If data loaded, show two-column layout. Otherwise placeholder. -->
            <div v-if="isDataLoaded" class="progress-container">
              <div class="d-flex" style="width: 100%;">
                <!-- LEFT COLUMN (30% width) -->
                <div class="d-flex flex-column" style="width: 30%;">
                  <!-- Row 1: index 0 -->
                  <div
                    class="d-flex flex-nowrap justify-content-center mb-3"
                    style="overflow-x: auto;"
                  >
                    <div
                      v-for="key in leftColumnRow1"
                      :key="key"
                      class="mx-2"
                    >
                      <RadialProgress
                        :key="key"
                        :totalSteps="stepsLabel[key]"
                        :completedSteps="limitedScoreReport[key]"
                        :startColor="colorByScore(limitedScoreReport[key])"
                        :stopColor="colorByScore(limitedScoreReport[key])"
                        :showProgress="true"
                        :text="`${formatLabel(key)}: ${limitedScoreReport[key]}`"
                        :diameter="150"
                        :strokeWidth="10"
                        :innerStrokeColor="'#ECECEC'"
                      >
                        <h3>{{ limitedScoreReport[key] }}</h3>
                        <p>{{ formatLabel(key) }}</p>
                      </RadialProgress>
                    </div>
                  </div>

                  <!-- Row 2: indexes [1,2] -->
                  <div
                    class="d-flex flex-nowrap justify-content-center"
                    style="overflow-x: auto;"
                  >
                    <div
                      v-for="key in leftColumnRow2"
                      :key="key"
                      class="mx-2"
                    >
                      <RadialProgress
                        :key="key"
                        :totalSteps="stepsLabel[key]"
                        :completedSteps="limitedScoreReport[key]"
                        :startColor="colorByScore(limitedScoreReport[key])"
                        :stopColor="colorByScore(limitedScoreReport[key])"
                        :showProgress="true"
                        :text="`${formatLabel(key)}: ${limitedScoreReport[key]}`"
                        :diameter="150"
                        :strokeWidth="10"
                        :innerStrokeColor="'#ECECEC'"
                      >
                        <h3>{{ limitedScoreReport[key] }}</h3>
                        <p>{{ formatLabel(key) }}</p>
                      </RadialProgress>
                    </div>
                  </div>
                </div>
                <!-- END LEFT COLUMN -->

                <!-- RIGHT COLUMN (70% width) -->
                <div class="d-flex flex-column" style="width: 70%;">
                  <!-- Row 1: indexes [3,4,5] -->
                  <div
                    class="d-flex flex-nowrap justify-content-center mb-3"
                    style="overflow-x: auto;"
                  >
                    <div
                      v-for="key in rightColumnRow1"
                      :key="key"
                      class="mx-2"
                    >
                      <RadialProgress
                        :key="key"
                        :totalSteps="stepsLabel[key]"
                        :completedSteps="limitedScoreReport[key]"
                        :startColor="colorByScore(limitedScoreReport[key])"
                        :stopColor="colorByScore(limitedScoreReport[key])"
                        :showProgress="true"
                        :text="`${formatLabel(key)}: ${limitedScoreReport[key]}`"
                        :diameter="150"
                        :strokeWidth="10"
                        :innerStrokeColor="'#ECECEC'"
                      >
                        <h3>{{ limitedScoreReport[key] }}</h3>
                        <p>{{ formatLabel(key) }}</p>
                      </RadialProgress>
                    </div>
                  </div>

                  <!-- Row 2: index [6] -->
                  <div
                    class="d-flex flex-nowrap justify-content-center"
                    style="overflow-x: auto;"
                  >
                    <div
                      v-for="key in rightColumnRow2"
                      :key="key"
                      class="mx-2"
                    >
                      <RadialProgress
                        :key="key"
                        :totalSteps="stepsLabel[key]"
                        :completedSteps="limitedScoreReport[key]"
                        :startColor="colorByScore(limitedScoreReport[key])"
                        :stopColor="colorByScore(limitedScoreReport[key])"
                        :showProgress="true"
                        :text="`${formatLabel(key)}: ${limitedScoreReport[key]}`"
                        :diameter="150"
                        :strokeWidth="10"
                        :innerStrokeColor="'#ECECEC'"
                      >
                        <h3>{{ limitedScoreReport[key] }}</h3>
                        <p>{{ formatLabel(key) }}</p>
                      </RadialProgress>
                    </div>
                  </div>
                </div>
                <!-- END RIGHT COLUMN -->
              </div>
            </div>
            <!-- Else placeholder text -->
            <div class="placeholder-text" v-else>
              ¡Toma tu primera prueba de Lenguaje del PAA!
            </div>
          </div>
        </div>
        <!-- END NON-MOBILE SCORE SECTION -->
        <div class="card greeting-card" style="width: 100%; min-height: 15vh; margin-top: 20px;" v-if="isMobile()" >
          <div class="card-body">
            <div class="progress-container" v-if="isDataLoaded">
                <div class="progress-item" style="width: 30%;" v-for="(score, label) in limitedScoreReport" :key="label">
                  <RadialProgress
                  :key="label"
                  :totalSteps=stepsLabel[label]
                  :completedSteps="score"
                  :startColor=colorByScore(100*score/stepsLabel[label])
                  :stopColor=colorByScore(100*score/stepsLabel[label])
                  :showProgress="true"
                  :text="`${formatLabel(label)}: ${score}`"
                  :diameter="isMobile() ? 90:150"
                  :strokeWidth="isMobile() ? 7:10"
                  :innerStrokeWidth="isMobile() ? 7:10"
                  :innerStrokeColor="'#ECECEC'"><p style="margin-top: 70px; margin-bottom: 30px;">{{formatLabel(label)}}</p><p>{{score}} / {{ stepsLabel[label] }}</p></RadialProgress>
                </div>
              </div>
            <div class="placeholder-text" v-else >¡Toma tu primera prueba de Lenguaje del PAA!</div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card practice-card" style="min-height: 25vh">
            <div class="col d-flex align-items-center">
              <div class="card-body">
              <h4 class="card-title">Problemas de Lectura</h4>
              <p class="card-text">
                Mejora tus habilidades y prepárate para la primera sección del examen
              </p>
              <button v-if="isSubscribed || this.remainingCredits.section1>0"
                class="btn btn-primary"
                @click="goToSection1Practice"
              >
              <span v-if="isSubscribed==1">Practicar</span>
              <span v-if="isSubscribed==0">Practicar ({{ this.remainingCredits.section1 }})</span>
              </button>
              <button v-else
                class="btn btn-primary"
                @click="goToPaymentPlans"
              >
              <span>Suscribirme</span>
            </button>
              <div v-if="!isSubscribed && this.remainingCredits.section1 <= 0"  style="margin-top: 10px;">Suscríbete para desbloquear</div>
            </div>
            </div>
          </div>
        <div class="card practice-card" style="min-height: 25vh">
            <div class="col d-flex align-items-center">
              <div class="card-body">
              <h4 class="card-title">Problemas de Redacción</h4>
              <p class="card-text">
                Mejora tus habilidades y prepárate para la segunda sección del examen
              </p>
              <button v-if="isSubscribed || this.remainingCredits.section2>0"
                class="btn btn-primary"
                @click="goToSection2Practice"
              >
              <span v-if="isSubscribed==1">Practicar</span>
              <span v-if="isSubscribed==0">Practicar ({{ this.remainingCredits.section2 }})</span>
              </button>
              <button v-else
                class="btn btn-primary"
                @click="goToPaymentPlans"
              >
              <span>Suscribirme</span>
            </button>
            <div v-if="!isSubscribed && this.remainingCredits.section2 <= 0" style="margin-top: 10px;">Suscríbete para desbloquear</div>
            </div>
            </div>
          </div>
          <!-- Add this card above the two cards on the right -->
      </div>
      <div class="col-md-6">
        <div class="row">
          <div class="col-12">
            <div class="card mock-test-card" style="min-height: 25vh">
            <div class="col d-flex align-items-center">
            <div class="card-body">
              <h4 class="card-title">Prueba de Aptitudes de Lenguaje</h4>
              <p class="card-text">Aprende a manejar tu tiempo y familiarízate con el examen</p>
              <button class="btn btn-primary" @click="goToMockTest" v-if="isSubscribed==1">Iniciar prueba</button>
              <button class="btn btn-primary" @click="goToPaymentPlans" v-else>Suscribirme</button>
              <p class="card-text" v-if="!isSubscribed==1" style="margin-top: 10px;">Suscríbete para desbloquear</p>
            </div>
            </div>
          </div>
          </div>
          <div class="col-12">
            <div class="card score-history-card" style="min-height: 25vh">
              <div class="col d-flex align-items-center">
                <div class="card-body">
                  <h4 class="card-title">Historial de Resultados</h4>
                  <p class="card-text">Evalúa tu progreso y detecta tus áreas de oportunidad para mejorar</p>
                  <div v-if="isSubscribed==1">
                  <button class="btn btn-primary" style="margin-right: 10px;" @click="goToScoreReportHistory">Ver historial</button>
                  <button class="btn btn-primary" style="margin-left: 10px;" @click="goToMetrics">Ver métricas</button>
                  </div>
                  <div v-else>
                    <button class="btn btn-primary" @click="goToPaymentPlans">Suscribirme</button>
                    <p class="card-text" style="margin-top: 10px;">Suscríbete para desbloquear</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import RadialProgress from "vue3-radial-progress";
  import { mapState, mapActions } from 'vuex';
  import { loadStripe } from '@stripe/stripe-js';

  

  export default {
    components: {
    RadialProgress,
},

computed: {
  ...mapState(['email', 'userId', 'isSubscribed', 'remainingCredits']),

  limitedScoreReport() {
    if (this.isMobile()) {
      // Mobile: first 3 items
      return Object.entries(this.scoreReport)
        .slice(0, 3)
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
    } else {
      // Desktop (non-mobile): first 7 items
      return Object.entries(this.scoreReport)
        .slice(0, 13)
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
    }
  },

  /**
   * Return the keys of limitedScoreReport in order
   */
  scoreKeys() {
    return Object.keys(this.limitedScoreReport);
  },

  // Left column
  leftColumnRow1() {
    // index [0]
    return this.scoreKeys.slice(0, 1);
  },
  leftColumnRow2() {
    // indices [1,2]
    return this.scoreKeys.slice(1, 3);
  },

  // Right column
  rightColumnRow1() {
    // indices [3,4,5]
    return this.scoreKeys.slice(3, 8);
  },
  rightColumnRow2() {
    // index [6]
    return this.scoreKeys.slice(8, 13);
  }
},


    data() {
      return {
        userName: '', // Replace this with actual user's name
        scoreReport: {
      Total: 0,
      Prueba1: 0,
      Prueba2: 0,
      Divulgacion: 0,
      Narrativo: 0,
      Lirico: 0,
      Graficas: 0,
      Comparado: 0,
      Elision: 0,
      Adicion: 0,
      Integracion: 0,
      Particularizacion: 0,
      Generalizacion: 0,
      Time_1: 0,
      Time_2: 0,
      Created_at: ''
    },
    stepsLabel:{
        Total: 800,
        Prueba1: 400,
        Prueba2: 400,
        Divulgacion: 100,
        Narrativo: 100,
        Lirico: 100,
        Graficas: 100,
        Comparado: 100,
        Elision: 100,
        Adicion: 100,
        Integracion: 100,
        Particularizacion: 100,
        Generalizacion: 100,
      },
      isDataLoaded: false,
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
      };
    },
    mounted() {
  // Fetch the user data
  axios
    .get(`${this.$baseURL}/api/users/language-user-data`, {
      params: {
        email: this.email || this.$route.query.email,
      },
    })
    .then((response) => {
      const data = response.data.data;
      this.userName = data.name;
      this.updateUserId(data.id);
      if(data.scoreReport!=null){
        this.scoreReport = data.scoreReport;
      }
      this.isDataLoaded = true;
      this.updateEmail(data.email);
      axios
    .get(`${this.$baseURL}/api/get-user-subscriptions`, {
      params: {
        user_id: this.userId,
      },
    })
    .then((response) => {
      const subscription_data = response.data.data[0];
      this.updateSubscription(subscription_data.subscription);
      this.updateRemainingCredits({section: 1, credits: subscription_data.multiple_choice_credits});
      this.updateRemainingCredits({section: 2, credits: subscription_data.open_answer_credits});
    })
    .catch((error) => {
      console.error('Error fetching subscription details:', error);
    });
    })
    .catch((error) => {
      console.error("Error fetching user data:", error);
    });

    
},

    methods: {

      isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

  formatLabel(label) {
        const labelMap = {
          'Prueba1': 'Prueba 1',
          'Prueba2': 'Prueba 2',
          'Divulgacion': 'Divulgación',
          'Narrativo': 'Narrativo',
          'Lirico': 'Lírico',
          'Graficas': 'Gráficas',
          'Comparado': 'Comparado',
          'Elision': 'Elisión',
          'Adicion': 'Adición',
          'Integracion': 'Integración',
          'Particularizacion': 'Particularización',
          'Generalizacion': 'Generalización'
        };
        return labelMap[label] || label;
      },

      ...mapActions(['updateUserId','updateSubscription', 'updateEmail','updateRemainingCredits']),

      async subscribe() {
  const stripe = await loadStripe("pk_test_51MydE2DbP1recgpH0UVwVp9UqZdCuScevzZsMCOGGkLrKyDfU1x8nQuTt9djn4Bns9Hm4A02QLfhA4T39wLE5NKw00U3G5raSn");
  axios
    .post(`${this.$baseURL}/api/create-checkout-session`, {
      user_id: this.userId,
      email: this.email // Change this property name to match the backend
    }) 
    .then((response) => {
      const sessionId = response.data.sessionId;
      stripe.redirectToCheckout({ sessionId });
    })
    .catch((error) => {
      console.error('Error creating checkout session:', error);
    });
},

goToPaymentPlans() {
    // Navigate to the Score Report History view
    this.$router.push("/payment-plan");
  },

      goToMetrics() {
    // Navigate to the Score Report History view
    this.$router.push("/language-user-metrics");
  },

      goToScoreReportHistory() {
    // Navigate to the Score Report History view
    this.$router.push("/language-scoreReportHistory");
  },

      goToSection1Practice() {
        // Navigate to the Section 1 practice view
        this.$router.push("/language-section1training");
      },
      goToSection2Practice() {
        // Navigate to the Section 2 practice view
        this.$router.push("/language-section2training");
      },
      goToMockTest() {
        // Navigate to the mock test view
        this.$router.push("/language-mockTest");
      },

      // New method for the language button
    goToLanguageStudentDashboard() {
      this.$router.push("/student-dashboard");
    },
    
      colorByScore(score) {
  const numericScore = Number(score);

  if (numericScore < 40) {
    return "#cc3232"; // red
  } else if (numericScore < 80) {
    return "#e7b416"; // yellow
  } else {
    return "#2dc937"; // green
  }
},
    },
  };
  </script>


  
  <style>

html, body {
  margin: 0;
  padding: 0;
}
  
  * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }

  .greeting-container {
    flex-grow: 1;
    display: flex;
    justify-content: center !important;
    text-align: center !important;
  }

  .card {
      background-color: white;
    }

  .greeting {
    color: white;
    margin: 0;
  }

  .header-logo {
    height: 50px; /* Adjust the size as needed */
    width: auto;
  }

  .user-image {
    width: 50px; /* Adjust the size as needed */
    height: auto;
  }

  body {
    background: #38b6ff;
  }

  .greeting-card,
  .practice-card,
  .mock-test-card {
    margin-bottom: 20px;
  }

  .greeting-card {
    background-color: rgba(255, 255, 255, 0.8);
  }

  .practice-card,
  .mock-test-card {
    background-color: rgba(255, 255, 255, 0.9);
  }

  .progress-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    position: relative;
    flex-wrap: wrap;
    max-width: 100%;
    max-height: 100%;
    z-index: 1; /* This will make sure the progress bars are above the other elements */
  }

  .progress-item {
    width: 12.5%; /* Adjust this value based on how many items you want to fit in a row */
    max-width: 100%; /* Adjust this value based on your preferred max-width */
  }
  .language-btn {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
</style>
