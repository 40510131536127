<template>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <!-- HEADER -->
        <div class="header" style="margin-top: 10px;">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo" />
          <!-- Toggle button for switching test type -->
          <router-link to="tutor-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          
        </div>

        <!-- GREETING -->
        <div class="greeting-container">
          <h1 class="greeting mb-4">¡Hola, {{ tutorName }}!</h1>
        </div>

        <!-- TUTOR BANK CARD (for tutorType==0) -->
        <div v-if="isDataLoaded && users.length != 0">
          <div class="card mb-4" v-if="tutorType == 0">
            <!-- If no bank info -->
            <div class="card-content" v-if="tutorBank == null">
              <div class="stats-container" style="width:100%">
                <div class="stat-column" style="width:100%">
                  <p class="stat-title">
                    Ingresa tus datos bancarios para empezar a recibir comisiones
                  </p>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="toEditPayouts"
                  >
                    Ingresar datos bancarios
                  </button>
                </div>
              </div>
            </div>
            
            <!-- Non-mobile bank info -->
            <div class="card-content" v-if="!isMobile() && tutorBank != null">
              <div class="stats-container" style="width:100%">
                <div class="stat-column" style="width:33%">
                  <p class="stat-number">{{ active_users }}</p>
                  <p class="stat-title">Alumnos suscritos</p>
                </div>
                <div class="stat-column" style="width:33%">
                  <p class="stat-number">\${{ cash_amount }}</p>
                  <p class="stat-title">Comisión actual</p>
                </div>
                <div class="stat-column" style="width:34%">
                  <p class="stat-date"><br /><br />{{ payout_date }}</p>
                  <p class="stat-title">Fecha de corte</p>
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="toEditPayouts"
                  >
                    Actualizar datos bancarios
                  </button>
                </div>
              </div>
            </div>

            <!-- Mobile bank info -->
            <div class="card-content" v-if="isMobile() && tutorBank != null">
              <div class="stats-container-mobile" style="width: 100%">
                <div class="stat-column">
                  <p class="stat-number">{{ active_users }}</p>
                  <p class="stat-title">Alumnos suscritos</p>
                </div>
                <div class="stat-column">
                  <p class="stat-number">\${{ cash_amount }}</p>
                  <p class="stat-title">Comisión actual</p>
                </div>
                <div class="stat-column stat-column-date">
                  <p class="stat-date">{{ payout_date }}</p>
                  <p class="stat-title">Fecha de corte</p>
                </div>
                <div class="stat-column stat-column-date">
                  <button
                    class="btn btn-primary"
                    type="button"
                    @click="toEditPayouts"
                  >
                    Actualizar datos bancarios
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div 
            style="
              margin: 20px 0; 
              display: flex; 
              gap: 10px; 
              justify-content: center;
            "
          >
            <button
              :class="currentTestType === 'math' 
                ? 'btn btn-primary' 
                : 'btn btn-outline-white'"
              @click="currentTestType = 'math'"
            >
              Matemáticas
            </button>

            <button
              :class="currentTestType === 'language' 
                ? 'btn btn-primary' 
                : 'btn btn-outline-white'"
              @click="currentTestType = 'language'"
            >
              Lenguaje
            </button>
          </div>
          
          <!-- SCORE REPORT CARDS FOR EACH USER -->
          <div
            class="card score-report-card mb-4"
            v-for="(user, index) in users"
            :key="index"
          >
            <!-- IF THE USER HAS NO SCORE DATA AT ALL (MATH OR LANGUAGE) -->
            <div class="card-body" v-if="!user.scoreReport && !user.languageScoreReport">
              <!-- Non-mobile layout with no data -->
              <div class="text-and-button-container" v-if="!isMobile()">
                <div class="remaining-time-container">
                  <div class="remaining-time-item">
                    {{ user.first_name }} {{ user.last_name }}
                    <div style="font-size: 12px;">
                      {{ user.email }} <br />
                      {{ user.phone_number }} <br />
                    </div>
                  </div>
                </div>
                <div class="view-exam-container">
                  <button
                    class="btn btn-primary"
                    @click="viewHistory(user.email)"
                  >
                    Contactar
                  </button>
                </div>
              </div>
              <!-- Mobile layout with no data -->
              <div class="row" v-if="isMobile()">
                <div class="col" style="width: 100%;">
                  <p style="text-align: left; margin-left: 20px;">
                    {{ user.first_name }} {{ user.last_name }}<br />
                    {{ user.email }}<br />
                    {{ user.phone_number }}
                  </p>
                </div>
                <div class="view-exam-container justify-content-center">
                  <button
                    class="btn btn-primary"
                    style="width: 60%; margin-bottom: 20px;"
                    @click="viewHistory(user.email)"
                  >
                    Contactar
                  </button>
                </div>
              </div>
              <div style="align-items: center; justify-content: center;">
                Este alumno aun no ha realizado una simulación del examen
              </div>
            </div>

            <!-- ELSE, SHOW THEIR SCORE REPORT (MATH OR LANGUAGE) -->
            <div v-else>
              <!-- NON-MOBILE CARD CONTENT -->
              <div class="card-content" v-if="!isMobile()">
                <!-- TOP ROW: user info + contact button -->
                <div class="text-and-button-container">
                  <div class="remaining-time-container">
                    <div class="remaining-time-item">
                      {{ user.first_name }} {{ user.last_name }}
                      <div style="font-size: 12px;">
                        {{ user.email }} <br />
                        {{ user.phone_number }} <br />
                        Última simulación: <br />
                        <span v-if="currentTestType === 'math' && user.scoreReport">
                          {{ formatDate(user.scoreReport.Created_at) }}
                        </span>
                        <span v-else-if="currentTestType === 'language' && user.languageScoreReport">
                          {{ formatDate(user.languageScoreReport.Created_at) }}
                        </span>
                        <span v-else>No disponible</span>
                      </div>
                    </div>
                  </div>
                  <div class="view-exam-container">
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="openWhatsapp(user.phone_number)"
                    >
                      Contactar
                    </button>
                  </div>
                </div>

                <!-- PROGRESS CONTAINER -->
                <div class="progress-container" style="margin: 10px;">
                  <!-- MATH ITEMS -->
                  <div
                    class="progress-item"
                    v-for="([label, score], i) in getMathItems(user)"
                    :key="i"
                  >
                    <RadialProgress
                      :key="label"
                      :totalSteps="stepsLabel[label]"
                      :completedSteps="score"
                      :startColor="colorByScore((score / stepsLabel[label]) * 100)"
                      :stopColor="colorByScore((score / stepsLabel[label]) * 100)"
                      :showProgress="true"
                      :text="`${label}: ${score}`"
                      :diameter="120"
                      :strokeWidth="7"
                      :innerStrokeWidth="7"
                      :innerStrokeColor="'#ECECEC'"
                    >
                      <h4>{{ score }}</h4>
                      <p>{{ formatLabel(label) }}</p>
                    </RadialProgress>
                  </div>

                                    <!-- LANGUAGE ITEMS (First row: 7 elements) -->
<div class="row" style="width: 100%; margin: 0 auto; justify-content: center;">
  <div
    class="progress-item col"
    v-for="([label, score], i) in getLanguageItems(user).slice(0, 3)"
    :key="'langRow1' + i"
    style="display: flex; justify-content: center; margin-bottom: 20px;"
  >
    <RadialProgress
      :key="label"
      :totalSteps="languageStepsLabel[label]"
      :completedSteps="score"
      :startColor="colorByScore((score / languageStepsLabel[label]) * 100)"
      :stopColor="colorByScore((score / languageStepsLabel[label]) * 100)"
      :showProgress="true"
      :text="`${label}: ${score}`"
      :diameter="120"
      :strokeWidth="7"
      :innerStrokeWidth="7"
      :innerStrokeColor="'#ECECEC'"
    >
      <h4>{{ score }}</h4>
      <p>{{ formatLanguageLabel(label) }}</p>
    </RadialProgress>
  </div>
</div>

                  <!-- LANGUAGE ITEMS (First row: 7 elements) -->
<div class="row" style="width: 100%; margin: 0 auto; justify-content: center;">
  <div
    class="progress-item col"
    v-for="([label, score], i) in getLanguageItems(user).slice(3, 8)"
    :key="'langRow1' + i"
    style="display: flex; justify-content: center; margin-bottom: 20px;"
  >
    <RadialProgress
      :key="label"
      :totalSteps="languageStepsLabel[label]"
      :completedSteps="score"
      :startColor="colorByScore((score / languageStepsLabel[label]) * 100)"
      :stopColor="colorByScore((score / languageStepsLabel[label]) * 100)"
      :showProgress="true"
      :text="`${label}: ${score}`"
      :diameter="120"
      :strokeWidth="7"
      :innerStrokeWidth="7"
      :innerStrokeColor="'#ECECEC'"
    >
      <h4>{{ score }}</h4>
      <p>{{ formatLanguageLabel(label) }}</p>
    </RadialProgress>
  </div>
</div>

<!-- LANGUAGE ITEMS (Second row: 6 elements) -->
<div class="row" style="width: 100%; margin: 0 auto; justify-content: center;">
  <div
    class="progress-item col"
    v-for="([label, score], i) in getLanguageItems(user).slice(8)"
    :key="'langRow2' + i"
    style="display: flex; justify-content: center; margin-bottom: 20px;"
  >
    <RadialProgress
      :key="label"
      :totalSteps="languageStepsLabel[label]"
      :completedSteps="score"
      :startColor="colorByScore((score / languageStepsLabel[label]) * 100)"
      :stopColor="colorByScore((score / languageStepsLabel[label]) * 100)"
      :showProgress="true"
      :text="`${label}: ${score}`"
      :diameter="120"
      :strokeWidth="7"
      :innerStrokeWidth="7"
      :innerStrokeColor="'#ECECEC'"
    >
      <h4>{{ score }}</h4>
      <p>{{ formatLanguageLabel(label) }}</p>
    </RadialProgress>
  </div>
</div>
                </div>

                <!-- BOTTOM ROW: Time + "Ver historial" -->
                <div class="text-and-button-container">
                  <!-- MATH TIME -->
                  <div
                    class="remaining-time-container"
                    v-if="currentTestType === 'math' && user.scoreReport"
                  >
                    <div class="remaining-time-item">
                      <strong>Sección 1</strong> <br />
                      Completado: {{ formatTime(user.scoreReport.Time_1) }} min
                    </div>
                    <div class="remaining-time-item">
                      <strong>Sección 2</strong> <br />
                      Completado: {{ formatTime(user.scoreReport.Time_2) }} min
                    </div>
                  </div>
                  <!-- LANGUAGE TIME -->
                  <div
                    class="remaining-time-container"
                    v-else-if="currentTestType === 'language' && user.languageScoreReport"
                  >
                    <div class="remaining-time-item">
                      <strong>Prueba 1</strong> <br />
                      Completado: {{ formatTime(user.languageScoreReport.Time_1) }} min
                    </div>
                    <div class="remaining-time-item">
                      <strong>Prueba 2</strong> <br />
                      Completado: {{ formatTime(user.languageScoreReport.Time_2) }} min
                    </div>
                  </div>
                  <div class="view-exam-container">
                    <button class="btn btn-primary" @click="viewHistory(user.email)">
                      Ver historial
                    </button>
                  </div>
                </div>
              </div>
              <!-- END NON-MOBILE -->

              <!-- MOBILE CARD CONTENT -->
              <div
                class="card-content"
                v-if="isMobile()"
                style="flex-wrap: wrap;"
              >
                <!-- TOP ROW: user info, date, contact -->
                <div class="row">
                  <div class="col" style="width: 100%;">
                    <p style="text-align: left; margin-left: 20px;">
                      {{ user.first_name }} {{ user.last_name }}<br />
                      {{ user.email }}<br />
                      {{ user.phone_number }}
                    </p>
                  </div>
                </div>
                <div class="progress-item" style="width: 100%; max-width: 100%;">
                  <div class="row">
                    <div class="col" style="width: 50%;">
                      <p style="text-align: left; margin-left: 20px;">Fecha</p>
                    </div>
                    <div class="col" style="width: 50%; margin-right: 20px;">
                      <p style="text-align: left; margin-left: 20px;">
                        <span v-if="currentTestType === 'math' && user.scoreReport">
                          {{ formatDate(user.scoreReport.Created_at) }}
                        </span>
                        <span v-else-if="currentTestType === 'language' && user.languageScoreReport">
                          {{ formatDate(user.languageScoreReport.Created_at) }}
                        </span>
                        <span v-else>No disponible</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="view-exam-container justify-content-center">
                  <button
                    class="btn btn-primary"
                    style="width: 60%; margin-bottom: 20px"
                    @click="openWhatsapp(user.phone_number)"
                  >
                    Contactar
                  </button>
                </div>

                <!-- MATH ITEMS (mobile) -->
                <div
                  class="progress-item"
                  style="width: 100%; max-width: 100%;"
                  v-for="([label, score], i) in getMathItems(user)"
                  :key="i"
                >
                  <div class="row">
                    <div class="col" style="width: 50%;">
                      <p style="text-align: left; margin-left: 20px;">
                        {{ formatLabel(label) }}
                      </p>
                    </div>
                    <div class="col" style="width: 50%; margin-right: 20px;">
                      <div
                        class="progress"
                        style="width: 100%; height: 10px; border-radius: 50px;"
                      >
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{
                            width: `${(score / stepsLabel[label]) * 100}%`,
                            'background-color': colorByScore((score / stepsLabel[label]) * 100),
                            height: '10px',
                            'border-radius': '50px'
                          }"
                          :aria-valuenow="score"
                          aria-valuemin="0"
                          :aria-valuemax="stepsLabel[label]"
                        ></div>
                      </div>
                      <p>{{ score }} / {{ stepsLabel[label] }}</p>
                    </div>
                  </div>
                </div>

                <!-- LANGUAGE ITEMS (mobile) -->
                <div
                  class="progress-item"
                  style="width: 100%; max-width: 100%;"
                  v-for="([label, score], i) in getLanguageItems(user)"
                  :key="i"
                >
                  <div class="row">
                    <div class="col" style="width: 50%;">
                      <p style="text-align: left; margin-left: 20px;">
                        {{ formatLanguageLabel(label) }}
                      </p>
                    </div>
                    <div class="col" style="width: 50%; margin-right: 20px;">
                      <div
                        class="progress"
                        style="width: 100%; height: 10px; border-radius: 50px;"
                      >
                        <div
                          class="progress-bar"
                          role="progressbar"
                          :style="{
                            width: `${(score / languageStepsLabel[label]) * 100}%`,
                            'background-color': colorByScore((score / languageStepsLabel[label]) * 100),
                            height: '10px',
                            'border-radius': '50px'
                          }"
                          :aria-valuenow="score"
                          aria-valuemin="0"
                          :aria-valuemax="languageStepsLabel[label]"
                        ></div>
                      </div>
                      <p>{{ score }} / {{ languageStepsLabel[label] }}</p>
                    </div>
                  </div>
                </div>

                <!-- TIME / HISTORIAL -->
                <!-- Math times -->
                <div
                  class="progress-item"
                  style="width: 100%; max-width: 100%;"
                  v-if="currentTestType === 'math' && user.scoreReport"
                >
                  <div class="row">
                    <div
                      class="col"
                      style="width: 50%; text-align: left; margin-left: 20px;"
                    >
                      <strong>Prueba 1</strong>
                    </div>
                    <div class="col" style="width: 50%; text-align: left;">
                      <p>{{ formatTime(user.scoreReport.Time_1) }} minutos</p>
                    </div>
                  </div>
                </div>
                <div
                  class="progress-item"
                  style="width: 100%; max-width: 100%;"
                  v-if="currentTestType === 'math' && user.scoreReport"
                >
                  <div class="row">
                    <div
                      class="col"
                      style="width: 50%; text-align: left; margin-left: 20px;"
                    >
                      <strong>Prueba 2</strong>
                    </div>
                    <div class="col" style="width: 50%; text-align: left;">
                      <p>{{ formatTime(user.scoreReport.Time_2) }} minutos</p>
                    </div>
                  </div>
                </div>
                <!-- Language times -->
                <div
                  class="progress-item"
                  style="width: 100%; max-width: 100%;"
                  v-if="currentTestType === 'language' && user.languageScoreReport"
                >
                  <div class="row">
                    <div
                      class="col"
                      style="width: 50%; text-align: left; margin-left: 20px;"
                    >
                      <strong>Prueba 1</strong>
                    </div>
                    <div class="col" style="width: 50%; text-align: left;">
                      <p>{{ formatTime(user.languageScoreReport.Time_1) }} minutos</p>
                    </div>
                  </div>
                </div>
                <div
                  class="progress-item"
                  style="width: 100%; max-width: 100%;"
                  v-if="currentTestType === 'language' && user.languageScoreReport"
                >
                  <div class="row">
                    <div
                      class="col"
                      style="width: 50%; text-align: left; margin-left: 20px;"
                    >
                      <strong>Prueba 2</strong>
                    </div>
                    <div class="col" style="width: 50%; text-align: left;">
                      <p>{{ formatTime(user.languageScoreReport.Time_2) }} minutos</p>
                    </div>
                  </div>
                </div>

                <!-- "Ver historial" button -->
                <div class="view-exam-container justify-content-center">
                  <button
                    class="btn btn-primary"
                    style="width: 60%;"
                    @click="viewHistory(user.email)"
                  >
                    Ver historial
                  </button>
                </div>
              </div>
              <!-- END MOBILE -->
            </div>
            <!-- END (has some sort of scoreReport) -->
          </div>
          <!-- END SCORE REPORT CARDS -->
        </div>
        <!-- END isDataLoaded && users.length != 0 -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import RadialProgress from "vue3-radial-progress";
import { mapState, mapActions } from 'vuex';

export default {
  components: { RadialProgress },

  computed: {
    ...mapState(['tutorEmail', 'tutorId', 'isSubscribedTutor']),
  },

  data() {
    return {
      /* Toggle between 'math' and 'language' */
      currentTestType: 'math',

      /* TUTOR INFO */
      tutorName: '',
      tutorCode: '',
      tutorType: null,
      tutorDate: '',
      tutorBank: '',
      tutorAccount: '',
      payout_date: '',
      active_users: 0,
      cash_amount: 0,

      /* USERS */
      users: [],

      /* MATH steps */
      stepsLabel: {
        Total: 800,
        Prueba1: 400,
        Prueba2: 400,
        Aritmetica: 100,
        Algebra: 100,
        Probabilidad: 100,
        Geometria: 100
      },

      /* LANGUAGE steps */
      languageStepsLabel: {
        Total: 800,
        Prueba1: 400,
        Prueba2: 400,
        Divulgacion: 100,
        Narrativo: 100,
        Lirico: 100,
        Graficas: 100,
        Comparado: 100,
        Elision: 100,
        Adicion: 100,
        Integracion: 100,
        Particularizacion: 100,
        Generalizacion: 100
      },

      isDataLoaded: false,
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
    };
  },

  mounted() {
    // Example fetch logic for tutor data, subscription, and user data
    axios
      .get(`${this.$baseURL}/api/users/tutor-data`, {
        params: {
          email: this.tutorEmail || this.$route.query.email,
        },
      })
      .then((response) => {
        const data = response.data.data;
        this.tutorName = data.first_name;
        this.updateTutorId(data.id);
        this.tutorCode = data.referral_code;
        this.updateTutorEmail(data.email);
        this.tutorType = data.is_school;
        this.tutorDate = data.created_at;
        this.tutorBank = data.bank_name;
        this.tutorAccount = data.bank_account;
        this.payoutDate();

        // Get subscription info for the tutor
        axios
          .get(`${this.$baseURL}/api/get-tutor-subscriptions`, {
            params: {
              tutor_id: data.id,
            },
          })
          .then((res) => {
            const subscription_data = res.data.data[0];
            this.updateTutorSubscription(subscription_data.subscription);

            // fetch the users by tutor code
            axios
              .get(`${this.$baseURL}/api/users-by-tutor-code`, {
                params: {
                  tutor_code: this.tutorCode,
                },
              })
              .then((resp) => {
                this.users = resp.data.data;
                
                // For each user, fetch more details
                this.users.forEach((u) => {
                  // MATH data
                  axios
                    .get(`${this.$baseURL}/api/users/user-data`, {
                      params: { email: u.email },
                    })
                    .then((mathResp) => {
                      const mathData = mathResp.data.data;
                      if (mathData?.scoreReport) {
                        u.scoreReport = mathData.scoreReport;
                        u.subscription = mathData.subscription;
                      } else {
                        u.scoreReport = null;
                      }
                      if (mathData?.subscription) {
                        u.subscription = mathData.subscription.Subscription;
                        u.next_charge_date = mathData.subscription.Next_Charge_Date;
                        
                        // Add to active users if subscription is valid
                        const currentDate = new Date();
                        const currentMonth = currentDate.getMonth(); // 0-based
                        const currentYear = currentDate.getFullYear();

                        const nextChargeDate = new Date(u.next_charge_date);
                        const nextChargeMonth = nextChargeDate.getMonth();
                        const nextChargeYear = nextChargeDate.getFullYear();

                        if (
                          u.subscription == 1 &&
                          (
                            (nextChargeYear === currentYear && nextChargeMonth === currentMonth) ||
                            (nextChargeYear === currentYear && nextChargeMonth === currentMonth + 1) ||
                            // December to January transition
                            (currentMonth === 11 && nextChargeYear === currentYear + 1 && nextChargeMonth === 0)
                          )
                        ) {
                          this.active_users += 1;
                          this.cash_amount += 100;
                        }
                      }
                    })
                    .catch((error) => {
                      console.error('Error fetching users data (math):', error);
                    });

                  // LANGUAGE data
                  axios
                    .get(`${this.$baseURL}/api/users/language-user-data`, {
                      params: { email: u.email },
                    })
                    .then((langResp) => {
                      const langData = langResp.data.data;
                      if (langData?.scoreReport) {
                        u.languageScoreReport = langData.scoreReport;
                      } else {
                        u.languageScoreReport = null;
                      }
                    })
                    .catch((error) => {
                      console.error('Error fetching users data (language):', error);
                    });
                });

                this.cashAmount();
                this.isDataLoaded = true;
              })
              .catch((error) => {
                console.error('Error fetching users by tutor code:', error);
              });
          })
          .catch((error) => {
            console.error('Error fetching tutor subscription details:', error);
          });
      })
      .catch((error) => {
        console.error('Error fetching tutor data:', error);
      });
  },

  methods: {
    ...mapActions(['updateTutorId', 'updateTutorSubscription', 'updateTutorEmail']),

    toggleTestType() {
      this.currentTestType = 
        this.currentTestType === 'math' ? 'language' : 'math';
    },

    toEditPayouts() {
      this.$router.push('/tutor-payouts');
    },

    payoutDate() {
      const datePart = this.tutorDate.split('T')[0];
      const [year, month] = datePart.split('-');
      const lastDayOfMonth = new Date(year, month, 0).getDate();
      const monthName = new Date(year, month - 1).toLocaleString('es-ES', {
        month: 'long',
      });
      const currentYear = new Date().getFullYear();

      this.payout_date = `${lastDayOfMonth} de ${monthName}, ${currentYear}`;
    },

    cashAmount() {
      this.cash_amount = this.active_users * 100;
    },

    /* Return array of [label, score] if currentTestType='math' and user has a math scoreReport */
    getMathItems(user) {
      if (this.currentTestType !== 'math' || !user?.scoreReport) return [];
      const reportObj = this.limitedScoreReport(user.scoreReport);
      return Object.entries(reportObj);
    },

    /* Return array of [label, score] if currentTestType='language' and user has a languageScoreReport */
    getLanguageItems(user) {
      if (this.currentTestType !== 'language' || !user?.languageScoreReport) return [];
      const reportObj = this.limitedLanguageScoreReport(user.languageScoreReport);
      return Object.entries(reportObj);
    },

    limitedScoreReport(scoreReport) {
      if (!scoreReport) return {};
      const {
        Total,
        Prueba1,
        Prueba2,
        Aritmetica,
        Algebra,
        Probabilidad,
        Geometria
      } = scoreReport;
      return {
        Total,
        Prueba1,
        Prueba2,
        Aritmetica,
        Algebra,
        Probabilidad,
        Geometria
      };
    },

    limitedLanguageScoreReport(scoreReport) {
      if (!scoreReport) return {};
      const {
        Total,
        Prueba1,
        Prueba2,
        Divulgacion,
        Narrativo,
        Lirico,
        Graficas,
        Comparado,
        Elision,
        Adicion,
        Integracion,
        Particularizacion,
        Generalizacion
      } = scoreReport;
      return {
        Total,
        Prueba1,
        Prueba2,
        Divulgacion,
        Narrativo,
        Lirico,
        Graficas,
        Comparado,
        Elision,
        Adicion,
        Integracion,
        Particularizacion,
        Generalizacion
      };
    },

    viewHistory(email) {
      this.$router.push({
        name: 'scoreReportHistory',
        query: { email }
      });
    },

    openWhatsapp(phone) {
      let digitsOnly = phone;
      if (phone.startsWith('+')) {
        digitsOnly = phone.substring(1);
      }
      if (digitsOnly.length === 10) {
        digitsOnly = '52' + digitsOnly;
      }
      window.open(`https://api.whatsapp.com/send?phone=${digitsOnly}`, '_blank');
    },

    formatDate(dateString) {
      if (!dateString) return 'No disponible';
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('es-ES', options);
    },

    isMobile() {
      return window.innerWidth <= 768;
    },

    formatTime(seconds) {
      if (!seconds) return '00:00';
      const min = Math.floor(seconds / 60);
      const sec = seconds % 60;
      return `${String(min).padStart(2, '0')}:${String(sec).padStart(2, '0')}`;
    },

    formatLabel(label) {
      const map = {
        Prueba1: 'Prueba 1',
        Prueba2: 'Prueba 2',
        Aritmetica: 'Aritmética',
        Algebra: 'Álgebra',
        Probabilidad: 'Probabilidad',
        Geometria: 'Geometría'
      };
      return map[label] || label;
    },

    formatLanguageLabel(label) {
      const map = {
        Prueba1: 'Prueba 1',
        Prueba2: 'Prueba 2',
        Divulgacion: 'Divulgacion',
        Narrativo: 'Narrativo',
        Lirico: 'Lirico',
        Graficas: 'Graficas',
        Comparado: 'Comparado',
        Elision: 'Elision',
        Adicion: 'Adicion',
        Integracion: 'Integracion',
        Particularizacion: 'Particularizacion',
        Generalizacion: 'Generalizacion'
      };
      return map[label] || label;
    },

    colorByScore(score) {
      const numericScore = Number(score);
      if (numericScore < 40) return '#cc3232'; // red
      if (numericScore < 80) return '#e7b416'; // yellow
      return '#2dc937'; // green
    }
  }
};
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.greeting-container {
  flex-grow: 1;
  display: flex;
  justify-content: center !important;
  text-align: center !important;
}

.card {
  background-color: white;
}

.greeting {
  color: white;
  margin: 0;
}

.header-logo {
  height: 50px;
  width: auto;
}

.user-image {
  width: 50px;
  height: auto;
}

body {
  background: #38b6ff;
}

.greeting-card,
.practice-card,
.mock-test-card {
  margin-bottom: 20px;
}

.greeting-card {
  background-color: rgba(255, 255, 255, 0.8);
}

.practice-card,
.mock-test-card {
  background-color: rgba(255, 255, 255, 0.9);
}

/* Stats Container (Tutor Bank Info) */
.stats-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-wrap: nowrap;
}
.stat-column {
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stat-number {
  font-size: 3em;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.stat-date {
  font-size: 1.5em;
  font-weight: bold;
  margin: 0;
  text-align: center;
}
.stat-title {
  font-size: 1em;
  color: #555;
  margin-top: 5px;
  text-align: center;
}
.stats-container-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.stats-container-mobile .stat-column {
  width: 100%;
  text-align: center;
}
.stat-column-date {
  margin-top: 20px;
}

/* Score Report Cards */
.card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.score-report-card {
  background-color: rgba(255, 255, 255, 0.9);
  margin-bottom: 20px;
}

/* Progress Bars & Items */
.progress-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex: 1;
  flex-wrap: wrap;
  max-height: 100%;
  z-index: 1;
  margin-left: 10px;
  margin-right: 10px;
}
.progress-item {
  width: calc(85% / 8); /* Adjust if needed */
  max-width: 80%;
}

/* Text & Button */
.text-and-button-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex: 1;
  max-width: 15%;
  margin-right: 0;
}
.remaining-time-container {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  width: 100%;
}
.remaining-time-item {
  text-align: left;
  margin-bottom: 0.5rem;
  margin-left: 10px;
}
.view-exam-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-left: 10px;
}
.view-exam-container button {
  width: 85%;
}

.btn-outline-white {
  color: #fff !important;       /* Text color */
  border-color: #fff !important; /* Border color */
}

/* Optional: Keep text white on hover, or tweak as desired */
.btn-outline-white:hover {
  color: #fff !important;
  border-color: #fff !important;
  background-color: transparent !important; /* or a translucent effect if you prefer */
}

/* Mobile adjustments, if needed */
@media (max-width: 768px) {
  .card-content {
    flex-direction: column;
  }
}
</style>
