<template>
  <div class="container">
    <div class="header">
          <img :src="HeaderLogo" alt="HeaderLogo" class="header-logo"/>
          <router-link to="user-profile">
            <img :src="userImage" alt="User" class="user-image" />
          </router-link>
          </div>
    <div class="row">
      <div class="col-12">
        <div class="card filter-card mb-4" v-if="!isMobile()">
          <div class="card-body" style="margin-top: 10px; margin-bottom: 10px; margin-right: 10px;">
            <div class="form-group">
              <p style="padding-top:20px">Ordenar por: </p>
            </div>
            <div class="form-group">
              <label for="sortDate" class="sr-only"></label>
              <select v-model="selectedSortDate" id="sortDate" class="form-control">
                <option disabled selected value="">Fecha</option>
                <option value="newest">Más recientes</option>
                <option value="oldest">Más antiguos</option>
              </select>
            </div>
            <div class="form-group">
              <label for="sortScore" class="sr-only"></label>
              <select v-model="selectedSortScore" id="sortScore" class="form-control">
                <option disabled selected value="">Puntuación</option>
                <option value="highest">Más altas</option>
                <option value="lowest">Más bajas</option>
              </select>
            </div>
            <div class="form-group">
              <label for="sortLabel" class="sr-only"></label>
              <select v-model="selectedSortLabel" id="sortLabel" class="form-control">
                <option disabled selected value="">Categoría</option>
                <option v-for="(step, label) in stepsLabel" :key="label" :value="label">
                  {{ formatLabel(label) }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="card filter-card" v-if="isMobile()">
          <div class="card-body" style="margin-right: 10px;">
            <div class="form-group" style="flex-basis: 40%;">
              <p style="padding-top:20px">Ordenar por: </p>
            </div>
            <div class="form-group" style="flex-basis: 40%;">
              <label for="sortDate" class="sr-only"></label>
              <select v-model="selectedSortDate" id="sortDate" class="form-control">
                <option disabled selected value="">Fecha</option>
                <option value="newest">Más recientes</option>
                <option value="oldest">Más antiguos</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
    <div class="col-12">
      <div v-if="isDataLoaded">
        <div
          class="card score-report-card mb-4"
          v-for="(scoreReport, index) in sortedScoreReports"
          :key="index"
        >
          <div class="card-body">
            <!-- Non-mobile section -->
<div class="card-content" v-if="!isMobile()">
  <!-- We replace the single "progress-container" + "v-for" with two columns & four rows. -->
  <div class="d-flex" style="width: 85%;">
    <!-- LEFT COLUMN (30%) -->
    <div class="d-flex flex-column" style="width: 30%;">
      <!-- Row 1: item #1 (index 0) -->
      <div
        class="d-flex flex-nowrap justify-content-center mb-3"
        style="overflow-x: auto;"
      >
        <div
          v-for="key in getLeftColumnRow1(scoreReport)"
          :key="key"
          class="mx-2"
        >
          <RadialProgress
            :key="key"
            :totalSteps="stepsLabel[key]"
            :completedSteps="limitedScoreReport(scoreReport)[key]"
            :startColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :stopColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :showProgress="true"
            :text="`${key}: ${limitedScoreReport(scoreReport)[key]}`"
            :diameter="130"
            :strokeWidth="8"
            :innerStrokeColor="'#ECECEC'"
          >
            <h4>{{ limitedScoreReport(scoreReport)[key] }}</h4>
            <p>{{ formatLabel(key) }}</p>
          </RadialProgress>
        </div>
      </div>

      <!-- Row 2: items #2, #3 (indexes 1,2) -->
      <div
        class="d-flex flex-nowrap justify-content-center"
        style="overflow-x: auto;"
      >
        <div
          v-for="key in getLeftColumnRow2(scoreReport)"
          :key="key"
          class="mx-2"
        >
          <RadialProgress
            :key="key"
            :totalSteps="stepsLabel[key]"
            :completedSteps="limitedScoreReport(scoreReport)[key]"
            :startColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :stopColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :showProgress="true"
            :text="`${key}: ${limitedScoreReport(scoreReport)[key]}`"
            :diameter="130"
            :strokeWidth="8"
            :innerStrokeColor="'#ECECEC'"
          >
            <h4>{{ limitedScoreReport(scoreReport)[key] }}</h4>
            <p>{{ formatLabel(key) }}</p>
          </RadialProgress>
        </div>
      </div>
    </div>
    <!-- END LEFT COLUMN -->

    <!-- RIGHT COLUMN (70%) -->
    <div class="d-flex flex-column" style="width: 70%;">
      <!-- Row 1: items #4..#8 (indexes 3..7) -->
      <div
        class="d-flex flex-nowrap justify-content-center mb-3"
        style="overflow-x: auto;"
      >
        <div
          v-for="key in getRightColumnRow1(scoreReport)"
          :key="key"
          class="mx-2"
        >
          <RadialProgress
            :key="key"
            :totalSteps="stepsLabel[key]"
            :completedSteps="limitedScoreReport(scoreReport)[key]"
            :startColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :stopColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :showProgress="true"
            :text="`${key}: ${limitedScoreReport(scoreReport)[key]}`"
            :diameter="130"
            :strokeWidth="8"
            :innerStrokeColor="'#ECECEC'"
          >
            <h4>{{ limitedScoreReport(scoreReport)[key] }}</h4>
            <p>{{ formatLabel(key) }}</p>
          </RadialProgress>
        </div>
      </div>

      <!-- Row 2: items #9..#13 (indexes 8..12) -->
      <div
        class="d-flex flex-nowrap justify-content-center"
        style="overflow-x: auto;"
      >
        <div
          v-for="key in getRightColumnRow2(scoreReport)"
          :key="key"
          class="mx-2"
        >
          <RadialProgress
            :key="key"
            :totalSteps="stepsLabel[key]"
            :completedSteps="limitedScoreReport(scoreReport)[key]"
            :startColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :stopColor="colorByScore(100 * limitedScoreReport(scoreReport)[key] / stepsLabel[key])"
            :showProgress="true"
            :text="`${key}: ${limitedScoreReport(scoreReport)[key]}`"
            :diameter="130"
            :strokeWidth="8"
            :innerStrokeColor="'#ECECEC'"
          >
            <h4>{{ limitedScoreReport(scoreReport)[key] }}</h4>
            <p>{{ formatLabel(key) }}</p>
          </RadialProgress>
        </div>
      </div>
    </div>
    <!-- END RIGHT COLUMN -->
  </div>

  <!-- The "time" data & "view exam" button remain below the row/column layout -->
  <div class="text-and-button-container">
    <div class="remaining-time-container">
      <div class="remaining-time-item">
        <strong>Sección 1</strong> <br />
        Completado: {{ formatTime(scoreReport.section1_remaining_time) }} min
      </div>
      <div class="remaining-time-item">
        <strong>Sección 2</strong> <br />
        Completado: {{ formatTime(scoreReport.section2_remaining_time) }} min
      </div>
    </div>
    <div class="view-exam-container">
      <button class="btn btn-primary" @click="viewExam(scoreReport.mock_test_id)">
        Ver examen
      </button>
    </div>
  </div>
</div>
<!-- END NON-MOBILE SECTION -->

    <div class="card-content justify-content-between" v-if="isMobile()" style="flex-wrap: wrap;">
      <div class="progress-item" style="width: 100%; max-width: 100%;" v-for="(score, label) in limitedScoreReport(scoreReport)" :key="label">        
        <div class="row">
          <div class="col" style="width: 50%;">
            <p style="text-align: left; margin-left: 20px;">{{formatLabel(label)}}</p>
          </div>
          <div class="col" style="width: 50%; margin-right: 20px;">
            <div class="progress" style="width: 100%; height: 10px; border-radius: 50px;">
    <div class="progress-bar" role="progressbar" :style="{ width: `${(score/stepsLabel[label])*100}%`, 'background-color': colorByScore(100*score/stepsLabel[label]), height: '10px', 'border-radius': '50px' }" :aria-valuenow="score" aria-valuemin="0" :aria-valuemax="stepsLabel[label]"></div>
</div>

        <p>{{score}} / {{stepsLabel[label]}}</p>

          </div>
        </div>
        </div>
        <div class="progress-item" style="width: 100%; max-width: 100%;">
          <div class="row">
              <div class="col" style="width: 50%; text-align: left; margin-left: 20px;">
                <strong>Prueba 1</strong>
              </div>
              <div class="col" style="width:50%; text-align: left;">
                <p>{{ formatTime(scoreReport.section1_remaining_time) }} minutos</p>
              </div>
            </div>
        </div>
        <div class="progress-item" style="width: 100%; max-width: 100%;">
          <div class="row">
              <div class="col" style="width: 50%; text-align: left; margin-left: 20px;">
                <strong>Prueba 2</strong>
              </div>
              <div class="col" style="width: 50%; text-align: left;">
                <p>{{ formatTime(scoreReport.section2_remaining_time) }} minutos</p>
              </div>
            </div>
        </div>
<div class="view-exam-container justify-content-center">
<button class="btn btn-primary" style="width: 60%;" @click="viewExam(scoreReport.mock_test_id)">
Ver examen
</button>
</div>
</div>
</div>
        
</div>
</div>
<div v-else>
<p>No se encontraron informes de calificaciones.</p>
</div>
</div>
</div>

  </div>
</template>
  
  <script>
  import axios from "axios";
  import RadialProgress from "vue3-radial-progress";
  import { mapState } from "vuex";
  
  export default {
    components: {
      RadialProgress,
    },
  
    computed: {
      ...mapState(["email"]),

      sortedScoreReports() {
      let sortedReports = [...this.scoreReports];

      // Sort by date
      sortedReports.sort((a, b) => {
        const aDate = new Date(a.created_at);
        const bDate = new Date(b.created_at);
        return this.selectedSortDate === "newest" ? bDate - aDate : aDate - bDate;
      });

      // Sort by score
      sortedReports.sort((a, b) => {
        const aScore = a[this.selectedSortLabel.toLowerCase().replace(" ", "_")];
        const bScore = b[this.selectedSortLabel.toLowerCase().replace(" ", "_")];
        return this.selectedSortScore === "highest" ? bScore - aScore : aScore - bScore;
      });

      return sortedReports;
    },
  },

  data() {
    return {
      scoreReports: [],
      stepsLabel:{
        Total: 800,
        Prueba1: 400,
        Prueba2: 400,
        Divulgacion: 100,
        Narrativo: 100,
        Lirico: 100,
        Graficas: 100,
        Comparado: 100,
        Elision: 100,
        Adicion: 100,
        Integracion: 100,
        Particularizacion: 100,
        Generalizacion: 100,
      },
      isDataLoaded: false,
      selectedSortDate: "",
      selectedSortScore: "",
      selectedSortLabel: "",
      userImage: require('@/assets/user.png'),
      HeaderLogo: require('@/assets/Mathpaas-white.png')
    };
  },

  mounted() {
    // Fetch the user data
    axios
      .get(`${this.$baseURL}/api/users/language-user-data`, {
        params: {
          email: this.email || this.$route.query.email,
        },
      })
      .then((response) => {
        const user_id = response.data.data.id;
        // Fetch score reports

        axios
          .get(`${this.$baseURL}/api/all_language_score_reports`, {
            params: {
              user_id: user_id,
            },
          })
          .then((response) => {
            this.scoreReports = response.data.data;
            this.isDataLoaded = true;
          })
          .catch((error) => {
            console.error("Error fetching score reports:", error);
          });
      })
      .catch((error) => {
        console.error("Error fetching user data:", error);
      });
  },

  methods: {

    getScoreKeys(scoreReport) {
    // Object.keys(...) returns the keys, e.g. ["Total","Seccion1","Seccion2",...]
    // We want them in the same order each time, so just use Object.keys on
    // whatever `limitedScoreReport(scoreReport)` gives back:
    return Object.keys(this.limitedScoreReport(scoreReport));
  },

      // LEFT COLUMN
  getLeftColumnRow1(scoreReport) {
    // Index 0 → just the first item
    return this.getScoreKeys(scoreReport).slice(0, 1);
  },
  getLeftColumnRow2(scoreReport) {
    // Indices [1, 2] → second & third items
    return this.getScoreKeys(scoreReport).slice(1, 3);
  },

  // RIGHT COLUMN
  getRightColumnRow1(scoreReport) {
    // Indices [3..7] → up to five items
    return this.getScoreKeys(scoreReport).slice(3, 8); 
  },
  getRightColumnRow2(scoreReport) {
    // Indices [8..12] → up to five items
    return this.getScoreKeys(scoreReport).slice(8, 13); 
  },

    isMobile() {
    return window.innerWidth <= 768; // Adjust this value based on your needs
  },

  formatLabel(label) {
        const labelMap = {
          'Prueba1': 'Prueba 1',
          'Prueba2': 'Prueba 2',
          'Divulgacion': 'Divulgación',
          'Narrativo': 'Narrativo',
          'Lirico': 'Lírico',
          'Graficas': 'Gráficas',
          'Comparado': 'Comparado',
          'Elision': 'Elisión',
          'Adicion': 'Adición',
          'Integracion': 'Integración',
          'Particularizacion': 'Particularización',
          'Generalizacion': 'Generalización'
        };
        return labelMap[label] || label;
      },

    formatTime(seconds) {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  },

  viewExam(mock_test_id) {
  this.$router.push({
    name: "LanguagePreviousExam",
    params: { mock_test_id },
  });
 },

    limitedScoreReport(scoreReport) {
      const {
        total_score,
        section1_score,
        section2_score,
        divulgacion,
        narrativo,
        lirico,
        grafico,
        comparado,
        elision,
        adicion,
        integracion,
        particularizacion,
        generalizacion,
        //observations,
      } = scoreReport;
      return {
        Total: total_score,
        Seccion1: section1_score,
        Seccion2: section2_score,
        Divulgacion: divulgacion,
        Narrativo: narrativo,
        Lirico: lirico,
        Graficas: grafico,
        Comparado: comparado,
        Elision: elision,
        Adicion: adicion,
        Integracion: integracion,
        Particularizacion: particularizacion,
        Generalizacion: generalizacion,
        //Observar: observations,
      };
    },

    colorByScore(score) {
  const numericScore = Number(score);

  if (numericScore < 40) {
    return "#cc3232"; // red
  } else if (numericScore < 80) {
    return "#e7b416"; // yellow
  } else {
    return "#2dc937"; // green
  }
},
  },
};
</script>

<style scoped>

.filter-card,
.score-report-card {
  display: flex;
  flex-wrap: wrap;
}

.card {
      background-color: white;
    }

.filter-card .card-body,
.score-report-card .card-body {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 0;
}

.form-group {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

select {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'><path d='M7 10l5 5 5-5z'/></svg>");
  background-repeat: no-repeat;
  background-position: right 0.5rem center;
  background-size: 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding-right: 1.5rem;
  text-align-last: center;
  width: 100%;
}

.progress-container {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex: 1;
    flex-wrap: wrap;
    max-height: 100%;
    z-index: 1;
  }

  .progress-item {
    width: calc(85% / 8); /* Adjust this value based on the total width percentage you want */
    max-width: 80%; /* Adjust this value based on your preferred max-width */
  }

  .text-and-button-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex: 1;
    max-width: 15%; /* Adjust this value based on the total width percentage you want */
    margin-right: 0; /* Remove the margin-right */
  }

  .remaining-time-container {
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    width: 100%;
  }

  .remaining-time-item {
    text-align: left;
    margin-bottom: 0.5rem;
    margin-left: 10px;
  }

  .view-exam-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-left: 10px;
  }

  .card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .view-exam-container button {
    width: 85%;
  }
</style>
